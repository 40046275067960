import CheckIcon from "@heroicons/react/24/solid/CheckIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { Box, Dialog, Typography } from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const DialogStyled = styling(Dialog)((props) => ({
  "& .MuiDialog-paper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "328px",
    borderRadius: "24px",
    border: `2px solid ${Color.black}`,
    [props.theme.breakpoints.up(768)]: {
      maxWidth: "670px",
    },
  },
}));

export const HeaderContainer = styling(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px 12px 8px 12px",
  rowGap: "2px",
  width: "100%",
  paddingInline: "12px",

  [props.theme.breakpoints.up(768)]: {
    paddingBlock: "16px",
    rowGap: "4px",
    paddingInline: "24px",
  },
}));

export const HeaderTitleContainer = styling(Box)((props) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const GreenCheckIcon = styling(CheckIcon)((props) => ({
  color: Color.white,
  height: "12px",
  width: "12px",
  background: Color.success,
  borderRadius: "50%",
  marginRight: "8px",
  padding: "1px",

  "& .MuiSvgIcon-root": {
    strokeWidth: 2,
  },

  [props.theme.breakpoints.up(768)]: {
    height: "24px",
    width: "24px",
    padding: "4px",
  },
}));

export const HeaderTitle = styling(Typography)((props) => ({
  fontSize: "1rem",
  fontWeight: 700,

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1.5rem",
  },
}));

export const CloseIcon = styling(XMarkIcon)({
  color: Color.black,
  cursor: "pointer",
  height: "24px",
  width: "24px",
});

export const HeaderDescription = styling(Typography)((props) => ({
  color: Color.darkGrey,
  fontSize: "0.75rem",
  fontWeight: 400,
  fontStyle: "italic",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "0.875rem",
  },
}));

export const HeaderDivider = styling("div")({
  height: "1px",
  width: "100%",
  border: `1px solid ${Color.grey}`,
});

export const ContentWrapper = styling(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  paddingBlock: "32px",
  paddingInline: "16px",

  [props.theme.breakpoints.up(768)]: {
    gap: "24px",
    flexDirection: "row",
    paddingBlock: "40px",
    paddingInline: "24px",
  },
}));

export const ContentContainer = styling(Box)((props) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  borderRadius: "8px",
  border: `2px solid ${Color.grey}`,
  padding: "24px 16px",
  transition: "border 0.3s",

  "&:hover": {
    border: `2px solid ${Color.hiredlyPurple}`,
    "& .MuiTypography-root": {
      color: Color.darkPurple,
    },
  },

  [props.theme.breakpoints.up(768)]: {
    flexDirection: "column",
    gap: "24px",
    width: "280px",
  },
}));

export const ResumeBuilderImage = styling("img")((props) => ({
  maxWidth: "110px",
  objectFit: "cover",

  [props.theme.breakpoints.up(768)]: {
    maxWidth: "100%",
    flex: "auto",
  },
}));

export const NoResumeImage = styling("img")((props) => ({
  maxWidth: "110px",
  objectFit: "cover",

  [props.theme.breakpoints.up(768)]: {
    maxWidth: "100%",
    flex: "auto",
    height: "108px",
  },
}));

export const ContentTextContainer = styling(Box)((props) => ({
  width: "124px",

  [props.theme.breakpoints.up(768)]: {
    width: "auto",
    textAlign: "center",
  },
}));

export const Title = styling(Typography)((props) => ({
  fontSize: "0.875rem",
  color: Color.black,
  fontWeight: 700,
  transition: "color 0.3s",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1.125rem",
  },
}));

export const ContentText = styling(Typography)((props) => ({
  fontSize: "0.75rem",
  color: Color.black,
  transition: "color 0.3s",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
    textWrap: "balance",
  },
}));
