import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAlertBanner } from "../../../redux/actions/dialog_action";
import {
  CloseButton,
  CloseIcon,
  DialogContainer,
  DialogContent,
  ShieldWarningIcon,
  TextContainer,
} from "./styles";

function SharedMaintenanceDialog(props) {
  const dispatch = useDispatch();
  const sessionStatus = sessionStorage.getItem("fraud-warning");

  const dialogStatus = useSelector((state) => state.dialog.showAlertBanner);

  function handleClose() {
    sessionStorage.setItem("fraud-warning", "false");
    dispatch(updateAlertBanner(false));
  }

  useEffect(() => {
    dispatch(
      updateAlertBanner(
        sessionStatus === null ? true : sessionStatus === "true"
      )
    );
  }, [sessionStatus]);

  return (
    <DialogContainer show={dialogStatus}>
      <DialogContent>
        <ShieldWarningIcon />
        <TextContainer>
          Beware of fraud! Hiredly never asks job seekers for payments. Report
          suspicious activity to <b>hello@hiredly.com</b>
        </TextContainer>
      </DialogContent>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
    </DialogContainer>
  );
}

export default SharedMaintenanceDialog;
