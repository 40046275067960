import InformationCircleIcon from "@heroicons/react/24/solid/InformationCircleIcon";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/Close";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, BoxProps, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import { withTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import styled, { css } from "styled-components";
import Color from "../../../assets/colors";

interface DialogStyledProps extends DialogProps {
  success?: boolean;
}

interface SwitchContainerProps extends BoxProps {
  show: boolean;
}

export const LinearProgressStyled = styling(LinearProgress)({});

export const DialogStyled = styling(Dialog)<DialogStyledProps>(
  (props: any) => ({

    "&&": {

      overflowY: "scroll",
    },

    "& .MuiDialog-container": {

      //When viewport height = dialog height then...
      "@media(max-height: 670px)":{

        height: "fit-content",
      },
    },

    "& .MuiDialog-paper": {
      minWidth: "670px",
      minHeight: props?.success ? "662px" : "auto",
      maxHeight: "662px",
      borderRadius: "24px",
      border: "2px solid black",

      [props.theme.breakpoints.down(768)]: {
        minWidth: "auto",
        maxWidth: "328px",
        maxHeight: "550px",
        minHeight: props?.success ? "490px" : "auto",
        marginInline: "16px",
      },

      // iPhone SE
      [props.theme.breakpoints.down(330)]: {
        minWidth: "auto",
        maxHeight: "400px",
        marginInline: "12px",
      },
    },
  })
);

export const CloseButton = styling(IconButton)({
  position: "absolute",
  top: 15,
  right: 10,
});

export const CloseRoundedIconStyled = styling(CloseRoundedIcon)({});

export const DialogTitleContainer = styling(Box)((props) => ({
  borderBottom: `1px solid ${Color.secondaryGrey}`,
}));

export const DialogTitleStyled = styling(DialogTitle)((props) => ({
  "& .MuiContainer-root": {
    padding: "0px",
  },

  paddingInline: "24px",
  paddingBlock: "24px 16px",
  fontWeight: "700",
  fontSize: "1.125rem",

  [props.theme.breakpoints.down(768)]: {
    fontSize: "1rem",
    padding: "20px",
  },

  // iPhone SE
  [props.theme.breakpoints.down(330)]: {
    maxWidth: "280px",
  },
}));

export const DialogContentWrapper = styling(Box)({
  // Nested container
  padding: "16px",
  height: "100%",
});

export const SuccessTitleContainer = styling(Box)((props) => ({}));

export const ImageGif = styling("img")((props) => ({
  position: "absolute",
  width: "150px",
  height: "150px",
  top: -40,
  right: 70,

  [props.theme.breakpoints.up(768)]: {
    width: "280px",
    height: "280px",
    top: -80,
    right: 100,
  },

  [props.theme.breakpoints.down(360)]: {
    right: 25,
    top: -56,
  },
}));

export const DialogContentStyled = styling(Box)(
  (props: { success: boolean }) => ({
    "&.MuiDialogContent-root": {
      padding: "0px",
    },
    flex: 1,
    position: "relative",
    display: props.success ? "flex" : "",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "scroll",
  })
);

export const DialogActionsStyled = styling(DialogActions)({
  "&.MuiDialogActions-root": {
    padding: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto",
  },

  borderTop: `1px solid ${Color.secondaryGrey}`,
});

export const WhiteButton = styling(Button)((props) => ({
  backgroundColor: Color.white,
  borderRadius: "100px",
  color: Color.black,
  textTransform: "none",
  border: `2px solid ${Color.black}`,
  fontSize: "1rem",
  fontWeight: 700,
  paddingInline: "32px",

  ":hover": {
    backgroundColor: Color.white,
  },

  [props.theme.breakpoints.down(768)]: {
    fontSize: "0.875rem",
    paddingInline: "14px",
  },
}));

export const EmptyContainer = styling(Container)({
  "&.MuiContainer-root": {
    padding: "0px",
  },

  padding: "0px",
});

export const CloseIconStyled = styling(CloseIcon)({});

export const IconButtonStyled = styling(IconButton)({});

export const HeaderCaption = styling(Typography)((props) => ({
  fontWeight: "400",
  fontSize: "0.875rem",

  [props.theme.breakpoints.down(768)]: {
    fontSize: "0.75rem",
  },
}));

export const BodyTypographyStyled = styling(Typography)({
  color: "#000000",
  fontWeight: "400",
  fontSize: "16px",
});

export const ButtonStyled = styling(Button)({
  backgroundColor: Color.black,
  borderRadius: "16px",
  height: "42px",
  color: Color.white,
  textTransform: "none",
  width: "150px",

  ":hover": {
    backgroundColor: Color.black,
    color: Color.white,
  },

  ":disabled": {
    backgroundColor: "#D8D8D8",
    color: Color.white,
  },
});

export const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      width: 4,
      overflow: "auto",
      zIndex: 10,
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: Color.hiredlyPurple,
      minHeight: 24,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      borderRadius: 8,
      backgroundColor: Color.lightHiredlyPurple,
      minHeight: 24,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: Color.grey,
      marginBottom: "55px",
    },
  },
}));

// Anchor Component //
export const StyledLink = styled.a`
  && {
    color: ${Color.hiredlyPurple};
    font-size: "0.8rem";
    display: inline;
  }
`;

export const LabelStyled = withTheme(styled(Typography)`
  && {
    font-weight: ${(props) => (props.caption == "true" ? "normal" : "700")};
    font-size: ${(props) => (props.caption == "true" ? "0.875rem" : "1.5rem")};

    ${(props) =>
    props.divider_text == "true" && {
      color: `${Color.secondaryGrey}`,
      margin: "0 10px",
    }};

    ${(props) =>
    props.disclaimer == "true" && {
      fontWeight: "normal",
      fontStyle: "italic",
      fontSize: "0.7rem",
      margin: "8px 0",
      textAlign: "center",
      whiteSpace: "normal",
    }};

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      ${(props) =>
    props.log_in_button == "true" &&
    css`
          margin: 0;
        `}
    }
  }
`);

export const SuccessContainer = styling(Grid)((props) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  gap: "38px",
  overflow: "hidden",

  [props.theme.breakpoints.down(768)]: {
    alignItems: "start",
    gap: "10px",
  },
}));

export const SuccessTitleTypography = styling(Typography)((props) => ({
  fontSize: "2rem",
  fontWeight: "700",
  color: "#321C7B",

  [props.theme.breakpoints.down(768)]: {
    fontSize: "1.25rem",
  },
}));

export const SuccessBodyTypography = styling(Typography)((props) => ({
  fontSize: "1rem",

  [props.theme.breakpoints.down(768)]: {
    fontSize: "0.875rem",
  },
}));

export const SuccessSpanTypography = styling("span")({
  fontSize: "14px",
  fontWeight: "700",
  color: "#321C7B",
});

export const SuccessTextWrapper = styling(Grid)((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",

  [props.theme.breakpoints.down(768)]: {
    textAlign: "start",
  },
}));

export const LoadingButtonStyled = styling(LoadingButton)((props) => ({
  backgroundColor: Color.buttonBlack,
  borderRadius: "100px",
  color: Color.white,
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 700,
  paddingInline: "32px",
  minHeight: "42px",
  minWidth: "135px",

  ":hover": {
    backgroundColor: Color.black,
    color: Color.white,
  },

  ":disabled": {
    backgroundColor: "#D8D8D8",
    color: Color.white,
  },

  [props.theme.breakpoints.down(768)]: {
    fontSize: "0.875rem",
    paddingInline: "14px",
  },
}));

export const CheckIdleIcon = styled("span")(({ theme }) => ({
  width: 26,
  height: 26,
  borderRadius: "6px",
  backgroundColor: Color.white,
  border: `1.5px solid ${Color.grey}`,
}));

export const CheckedIcon = styling(CheckRoundedIcon)({
  width: 26,
  height: 26,
  color: Color.white,
  background: Color.black,
  borderRadius: "6px",
});

export const CheckLabel = styling(Typography)({
  fontSize: "0.875rem",
  fontWeight: 700,
});

export const SwitchContainer = styling(Box)<SwitchContainerProps>((props) => ({
  position: "sticky",
  bottom: "0",
  background: Color.lightGrey,
  borderRadius: "16px 16px 0px 0px",
  paddingBlock: "16px",
  paddingInline: "20px 40px",
  transform: `translateY(${props?.show ? "50px" : "0px"})`,
  transition: "all cubic-bezier(0.4, 0, 0.2, 1) 0.4s",
  transitionDelay: "0.5s",

  [props.theme.breakpoints.down(768)]: {
    paddingRight: "20px",
    transform: `translateY(${props?.show ? "120px" : "0px"})`,
    transitionDelay: "0s",
  },
}));

export const SwitchHeaderContainer = styling(Box)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const SwitchTitleContainer = styling(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

export const InformationCircleIconStyled = styling(InformationCircleIcon)({
  cursor: "pointer",
  width: "20px",
  height: "20px",
});

export const IOSSwitch = styled(Switch).attrs(() => ({
  classes: {
    root: "root",
    switchBase: "switchBase",
    thumb: "thumb",
    track: "track",
    checked: "checked",
  },
  disableRipple: true,
}))`
  &.root {
    width: 46px;
    height: 24px;
    padding: 0;
  }

  .switchBase {
    padding: 1px;
    transform: translate(2px, 2px);

    &.checked {
      transform: translate(23px, 2px);
      color: white;
      & + .track {
        background-color: ${Color.hiredlyPurple};
        opacity: 1;
        border: none;
      }
    }

    &.focusVisible &.thumb {
      color: #52d869;
      border: 6x sold ${Color.white};
    }
  }

  .thumb {
    width: 18px;
    height: 18px;
  }

  & .track {
    border-radius: 36px;
    border: 1px solid #bdbdbd;
    background-color: ${Color.secondaryGrey};
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
      border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

export const SwitchTitle = styling(Typography)({
  fontSize: "0.875rem",
  fontWeight: 700,
});

export const SwitchDescription = styling(Typography)({
  fontSize: "0.75rem",
  marginTop: "4px",
});
