import { uploadResume } from "../redux/actions/user_action";
import * as types from "../redux/types/user_type";
import { convertBase64 } from "./data_management";

type UploadResumeParams = {
  resume: any;
  canCancel: boolean;
  state: string;
};

type UploadResumeUserObj = {
  resume: string | undefined;
  approved: boolean | undefined;
  state: string | undefined;
};

async function useUploadResume(file: File, dispatch: any) {
  if (!file) return false;

  //  if file is larger than 2MB, then don't allow for upload
  if (parseFloat((file.size / 1024 / 1024).toFixed(4)) > 2) {
    return false;
  }

  if (
    !(
      file.name?.includes(".docx") ||
      file.name?.includes(".doc") ||
      file.name?.includes(".pdf")
    )
  ) {
    return false;
  }

  // convert file to base64 and trigger upload resume API

  const base64File = await convertBase64(file);

  dispatch(uploadResume(base64File)).then((response: any) => {
    if (response.type === types.UPLOAD_RESUME_SUCCESS) {
      // posthog.capture("onboarding_resume_upload", {
      //   origin: "resume_onboarding",
      //   first_time: true,
      // });
    }
  });
}

export { useUploadResume };
