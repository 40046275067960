import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  IconButtonProps,
  LinearProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  TypographyProps,
  AutocompleteProps,
} from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import { CustomInputProps } from "./types";

interface ExtendedAutoCompleteProps<T>
  extends AutocompleteProps<T, boolean, boolean, boolean> {
  error?: boolean;
}

interface SelectButtonProps extends IconButtonProps {
  selected?: boolean;
  big?: boolean;
}

interface SectionCardWrapperProps extends BoxProps {
  editMode?: boolean;
  selected?: boolean;
  disableHover?: boolean;
  noPaddingBottom?: boolean;
  error?: boolean;
}

interface SectionCardProps extends BoxProps {
  showFullCard?: boolean;
}

interface ExtendedButtonProps extends ButtonProps {
  big?: boolean;
}

interface CharacterCountProps extends TypographyProps {
  disable?: boolean;
  error?: boolean;
}

interface DialogStyledProps extends DialogProps {
  loading: boolean;
}

interface ContentTextProps extends TypographyProps {
  maxWidth?: number;
  leftAlign?: boolean;
}

export const DialogStyled = styling(Dialog)<DialogStyledProps>(
  (props: any) => ({
    "&&": {

      overflowY: "scroll",
    },
    "& .MuiDialog-container": {

      //When viewport height = dialog height then...
      "@media(max-height: 670px)":{

        height: "fit-content",
      },
    },
    "& + .MuiTooltip-popper": {
      // Allow tooltip to show on top of dialog
      zIndex: "3000",
    },
    "& .MuiDialog-paper": {
      borderRadius: "24px",
      border: `2px solid ${Color.black}`,
      minHeight: "500px",
      maxHeight: "632px",
      minWidth: "328px",
      maxWidth: "328px",
      overflow: "hidden",
      justifyContent: "center",
      marginInline: "18px",
      display: props?.loading ? "flex" : "grid",
      gridTemplateColumns: props?.loading ? "auto" : "1fr",
      gridTemplateRows: props?.loading ? "auto" : "auto 1fr auto",

      [props.theme.breakpoints.up(768)]: {
        minWidth: "670px",
        maxwidth: "670px",
      },
    },
  })
);

export const CloseButton = styling(IconButton)({
  position: "absolute",
  top: 5,
  right: 10,
});

export const CloseRoundedIconStyled = styling(CloseRoundedIcon)({});

interface LoadingContentWrapperProps extends BoxProps {
  loading?: boolean;
  gap?: number;
}

export const LoadingContentWrapper = styling(Box)<LoadingContentWrapperProps>(
  (props) => ({
    height: "100%",
    paddingInline: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: props?.gap,
    transition: "opacity 0.5s",
    paddingLeft: props?.loading ? "auto" : "0px",
    paddingBlock: "60px",
  })
);

export const ContentTitle = styling(Typography)<ContentTextProps>((props) => ({
  fontSize: "1.563rem",
  fontWeight: 700,
  color: Color.darkPurple,
  textAlign: props?.leftAlign ? "left" : "center",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "2rem",
    textAlign: "center",
  },
}));

export const ContentText = styling(Typography)<ContentTextProps>((props) => ({
  fontSize: "1rem",
  textAlign: props?.leftAlign ? "left" : "center",
  maxWidth: props?.maxWidth,

  [props.theme.breakpoints.up(768)]: {
    textAlign: "center",
  },
}));

export const LinearProgressStyled = styling(LinearProgress)((props) => ({
  width: "300px",
  borderRadius: 5,
  marginBlock: "16px",
}));

export const DialogContentWrapper = styling(DialogContent)((props) => ({
  position: "relative",
  padding: "0",
  display: "flex",
  flexDirection: "column-reverse",
  justifyContent: "center",

  [props.theme.breakpoints.up(768)]: {
    flexDirection: "column",
  },
}));

export const DialogTitleContainer = styling(Box)({
  minHeight: "fit-content",
  borderBottom: `1px solid ${Color.secondaryGrey}`,
  padding: 0,
});

export const DialogTitleStyled = styling(DialogTitle)((props) => ({
  "& .MuiContainer-root": {
    padding: "0px",
  },

  fontWeight: "700",
  fontSize: "1rem",
  maxWidth: "224px",
  paddingBlock: "24px 16px",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1.125rem",
    // padding: "20px",
    maxWidth: "none",
  },
}));

export const BetaLabel = styling(Typography)((props) => ({
  position: "absolute",
  top: 16,
  right: 100,
  fontSize: "0.625rem",

  [props.theme.breakpoints.up(768)]: {
    right: 310,
  },
}));

export const SelectContainer = styling(Box)((props) => ({
  position: "sticky",
  zIndex: 1000,
  bottom: 0,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  background: Color.lightPurple,
  padding: "8px 16px",

  [props.theme.breakpoints.up(768)]: {
    top: 0,
    bottom: "auto",
  },
}));

export const RegularText = styling(Typography)((props) => ({
  fontSize: "0.875rem",
  [props.theme.breakpoints.down(768)]: {
    fontSize: "0.75rem",
  },
}));

export const SelectButton = styling(IconButton)<SelectButtonProps>((props) => ({
  borderRadius: "4px",
  border: `1.5px solid ${Color.black}`,
  width: props.big ? "33px" : "24px",
  height: props.big ? "33px" : "24px",
  padding: props.big ? "4px" : "0px",
  background: props.selected ? Color.black : Color.white,
  "& .MuiSvgIcon-root": {
    color: props.selected ? Color.white : Color.black,
  },

  [props.theme.breakpoints.down(768)]: {
    width: props.big ? "33px" : "20px",
    height: props.big ? "33px" : "20px",
  },

  // This will apply to only devices that can hover
  "@media (hover: hover)": {
    "&:hover": {
      background: Color.buttonBlack,
      "& .MuiSvgIcon-root": {
        color: Color.white,
      },
    },
  },
}));

export const SelectAllTitle = styling(RegularText)((props) => ({
  [props.theme.breakpoints.down(768)]: {
    maxWidth: "188px",
    fontSize: "0.75rem",
  },
}));

export const CheckIconStyled = styling(CheckRoundedIcon)({
  width: "100%",
  height: "100%",
});

export const SelectAllButtonContainer = styling(Box)((props) => ({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",

  [props.theme.breakpoints.down(768)]: {
    flexDirection: "row-reverse",
  },
}));

export const DialogActionsStyled = styling(DialogActions)({
  "&.MuiDialogActions-root": {
    paddingBlock: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  borderTop: `1px solid ${Color.secondaryGrey}`,
});

export const XMarkIconStyled = styling(XMarkIcon)({
  width: "21px",
  height: "21px",
  color: Color.black,
});

export const BlackButton = styling(Button)<ExtendedButtonProps>(
  (props: any) => ({
    color: Color.white,
    fontSize: "0.75rem",
    fontWeight: 700,
    background: Color.black,
    borderRadius: "100px",
    paddingInline: "32px",
    textTransform: "none",
    width: props.big ? "200px" : "auto",
    height: props.big ? "48px" : "auto",
    marginTop: props.big ? "24px" : "0px",

    "&:hover": {
      background: Color.buttonBlack,
    },

    "&.Mui-disabled": {
      color: Color.white,
      opacity: 0.38,
    },

    [props.theme.breakpoints.up(768)]: {
      fontSize: "1rem",
      marginTop: "0px",
    },
  })
);

export const WhiteButton = styling(Button)((props) => ({
  color: Color.black,
  fontSize: "0.75rem",
  fontWeight: 700,
  background: Color.white,
  border: `2px solid ${Color.black}`,
  borderRadius: "100px",
  paddingInline: "32px",
  paddingBlock: "4px",
  textTransform: "none",

  "&:hover": {
    color: Color.white,
    background: Color.black,
  },

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
  },
}));

export const TextButton = styling(Button)((props) => ({
  fontSize: "0.75rem",
  color: Color.black,
  fontWeight: 700,
  textTransform: "none",
  paddingInline: "32px",
  borderRadius: "100px",

  "&:hover": {
    background: "rgba(0,0,0,0.0.5)",
  },

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
  },
}));

export const SectionTitle = styling(Typography)((props) => ({
  fontSize: "1.125rem",
  fontWeight: 700,

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1.25rem",
  },
}));

export const DialogContentContainer = styling(Box)({
  paddingInline: "24px",
  paddingBlock: "8px 16px",
  overflowY: "scroll",
  height: "100%",

  "& .MuiTypography-h3:not(:first-of-type)": {
    marginTop: "40px",
  },
});

export const SectionCardWrapper = styling(Box)<SectionCardWrapperProps>(
  (props: any) => ({
    cursor: props.editMode ? "" : "pointer",
    position: "relative",
    padding: "16px",
    marginTop: "16px",
    borderRadius: "8px",
    border: `2.5px solid ${
      props.selected
        ? props?.error
          ? Color.error
          : Color.darkPurple
        : "#F8F8F8"
    }`,
    background: "#F8F8F8",
    transition: "all ease-in-out 0.2s",
    overflow: "hidden",
    paddingBottom: !props.noPaddingBottom && "40px",
    "&:hover": {
      borderColor: props.disableHover
        ? "#F8F8F8"
        : props.editMode
        ? ""
        : props?.selected
        ? props?.error
          ? Color.error
          : Color.darkPurple
        : Color.darkPurple,
    },
  })
);

export const SectionCard = styling(Box)<SectionCardProps>((props: any) => ({
  overflow: "hidden",
  maxHeight: props?.showFullCard ? "none" : "158px",
}));

export const CardHeaderContainer = styling(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const CardHeader = styling(Box)({});

export const ButtonGroup = styling(Box)({
  display: "flex",
  gap: "8px",
});

export const CardTitle = styling(Typography)((props) => ({
  fontSize: "0.875rem",
  fontWeight: 700,
}));

export const CardSubtitle = styling(Typography)((props) => ({
  fontSize: "0.875rem",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
  },
}));

export const CardCaption = styling(Typography)((props) => ({
  fontSize: "0.875rem",
  color: "rgba(0, 0, 0, 0.6)",
}));

export const IconButtonStyled = styling(IconButton)({
  borderRadius: "8px",
  border: `1.5px solid ${Color.black}`,
  width: "33px",
  height: "33px",
  padding: "4px",
});

export const PencilIconStyled = styling(PencilIcon)({
  width: "21px",
  height: "21px",
  color: Color.black,
});

export const CardContentList = styling("ul")((props) => ({
  marginBottom: "0",
}));

export const CardContentText = styling("li")((props: any) => ({
  fontSize: "0.875rem",
  marginLeft: "-20px",
  paddingLeft: "-10px",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
  },
}));

export const SeeMoreButton = styling(Button)({
  position: "absolute",
  left: 10,
  bottom: 4,
  textTransform: "none",
  color: Color.hiredlyPurple,
  fontSize: "0.875rem",
  fontWeight: 700,
});

// EditCard
export const FormWrapper = styling("form")((props) => ({
  marginTop: "16px",

  [props.theme.breakpoints.up(768)]: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
  },
}));

export const FormAction = styling(Box)((props) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  gap: "8px",

  [props.theme.breakpoints.down(768)]: {
    flexDirection: "column-reverse",
    alignItems: "center",
  },
}));

export const FormInput = styling(Box)((props: any) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  flex: 1,
  marginTop: "16px",
  minWidth: "200px",

  [props.theme.breakpoints.up(768)]: {
    marginTop: "0px",
  },
}));

export const InputGroup = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  maxWidth: "max-content",

  [props.theme.breakpoints.down(768)]: {
    maxWidth: "none",
  },
}));

export const FormLabel = styling("label")({
  fontSize: "0.875rem",
  fontWeight: 700,
});

export const InputContainer = styling("div")({
  position: "relative",
  display: "flex",
  width: "100%",
});

export const ErrorIcon = styling(ErrorOutlineRoundedIcon)({
  position: "absolute",
  color: Color.error,
  top: 9,
  right: 10,
});

export const InputStyled = styling("input")<CustomInputProps>((props) => ({
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "1rem",
  fontFamily: "inherit",
  padding: "12px 16px",
  borderRadius: "8px",
  outline: "none",
  background: Color.white,
  border: `2px solid ${props.error ? Color.error : Color.secondaryGrey}`,
  opacity: 0.72,
  flex: 1,
  overflow: "hidden",

  ":hover": {
    borderColor: Color.hiredlyPurple,
  },

  ":focus": {
    borderColor: Color.hiredlyPurple,
  },
}));

export const InputErrorText = styling(Typography)((props) => ({
  color: Color.error,
  fontSize: "0.875rem",
  paddingLeft: "16px",

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
  },
}));

export const FormControlLabelStyled = styling(FormControlLabel)({
  width: "100%",
});

export const CheckboxStyled = styling(Checkbox)({});

export const AutoCompleteStyled = styling(Autocomplete)<
  ExtendedAutoCompleteProps<any>
>((props) => ({
  padding: "0",
  flex: 1,

  "& fieldset": {
    border: `2px solid ${
      props?.error ? Color.error : Color.secondaryGrey
    } !important`,
  },

  "&:hover fieldset": {
    border: `2px solid ${Color.hiredlyPurple} !important`,
  },

  [props.theme.breakpoints.up(768)]: {
    minWidth: "130px",
  },
}));

export const TextFieldStyled = styling(TextField)((props) => ({
  width: "100%",
  marginBottom: "16px",
  borderRadius: "8px",
  backgroundColor: Color.white,
  padding: "0",
  "&.MuiFormControl-root": {
    marginBottom: "0",
    "& .MuiOutlinedInput-root": {
      padding: "0",
      paddingInline: "12px",
      paddingBlock: "4px",
      borderRadius: "8px",
      opacity: 0.72,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Color.secondaryGrey}`,
      },
      "& .MuiOutlinedInput-input:focus-within + .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "red",
        },
    },
  },

  "&.MuiFormControl-root:hover, &.MuiFormControl-root:focus-within": {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Color.hiredlyPurple}`,
      },
    },
  },
}));

export const EditAboutContentContainer = styling(Grid)((props) => ({
  position: "relative",
  width: "100%",
}));

export const AboutTextField = styling(TextField)((props: any) => ({
  backgroundColor: Color.white,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    display: "flex",
    alignItems: "start",
    padding: "16px 20px",
    minHeight: "180px",
    "& fieldset": {
      border: `2px solid ${props?.error ? Color.error : Color.secondaryGrey}`,
    },
    "&:hover fieldset": {
      border: `2px solid ${
        props?.error ? Color.error : Color.buttonBorderPurple
      }`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${
        props?.error ? Color.error : Color.buttonBorderPurple
      }`,
    },
  },
}));

export const CharacterCount = styling(Typography)<CharacterCountProps>(
  (props: any) => ({
    color: props.disable === true ? "red" : "",
    zIndex: 10,
    fontSize: "13px",
    fontWeight: 400,
    position: "absolute",
    right: "0",
    bottom: props?.error ? 21 : 0,
    transform: "translate(-1rem, -0.5rem)",

    [props.theme.breakpoints.down(768)]: {
      bottom: props?.error ? 38 : 0,
    },
  })
);

export const SkillCardContainer = styling(Box)({});

export const ToggleButtonGroupStyled = styling(ToggleButtonGroup)({
  display: "flex",
  gap: "16px",
  flexWrap: "wrap",

  "&.MuiToggleButtonGroup-root": {
    "& .MuiToggleButtonGroup-grouped": {
      ":last-of-type": {
        borderRadius: "100px",
        border: `1.5px solid ${Color.lightPurple}`,

        ":hover": {
          borderColor: Color.hiredlyPurple,
        },
      },
      ":not(:last-of-type)": {
        borderRadius: "100px",
        border: `1.5px solid ${Color.lightPurple}`,

        ":hover": {
          borderColor: Color.hiredlyPurple,
        },
      },

      "&.Mui-selected": {
        borderLeft: "1.5px solid !important",
        borderColor: Color.hiredlyPurple,
      },
    },
  },
});

export const SkillToggleButton = styling(ToggleButton)({
  color: "rgba(0, 0, 0, 0.6)",
  background: Color.lightPurple,
  padding: "8px 16px",
  textTransform: "none",

  ":hover": {
    borderColor: Color.hiredlyPurple,
    background: Color.lightPurple,
  },

  "&.Mui-selected": {
    border: `1.5px solid ${Color.lightPurple}`,
    background: Color.lightPurple,

    ":hover": {
      background: Color.lightPurple,
    },
  },
});

export const TrashButtonContainer = styling(Box)((props) => ({
  display: "flex",
  width: "100%",
  justifyContent: "end",

  [props.theme.breakpoints.up(768)]: {
    // display: "block",
    width: "auto",
    alignItems: "end",
  },
}));

export const TrashIcon = styling(DeleteForever)({
  color: Color.secondaryGrey,
});

export const TrashIconButtonStyled = styling(IconButton)((props) => ({}));

export const LinkIconStyled = styling(InsertLinkRoundedIcon)({
  color: Color.darkGrey,
});

export const TooltipStyled = styling(Tooltip)({});

export const LinkIconButtonStyled = styling(IconButton)({
  width: "33px",
  height: "33px",
});

export const FileInput = styling("input")({
  display: "none",
});

export const LoadingButtonStyled = styling(LoadingButton)((props) => ({
  color: Color.black,
  fontSize: "0.75rem",
  fontWeight: 700,
  background: Color.white,
  border: `2px solid ${Color.black}`,
  borderRadius: "100px",
  paddingInline: "32px",
  paddingBlock: "4px",
  textTransform: "none",

  "&:hover": {
    color: Color.white,
    background: Color.black,
  },

  ":disabled": {
    backgroundColor: "#D8D8D8",
    color: Color.white,
  },

  [props.theme.breakpoints.up(768)]: {
    fontSize: "1rem",
  },

  [props.theme.breakpoints.down(768)]: {
    paddingInline: "0",
    width: "129px",
  },
}));

export const CompleButton = styling(Button)<ExtendedButtonProps>(
  (props: any) => ({
    color: Color.white,
    fontSize: "0.75rem",
    fontWeight: 700,
    background: Color.black,
    borderRadius: "100px",
    paddingInline: "32px",
    textTransform: "none",
    width: "auto",
    height: "auto",
    marginTop: "0px",

    "&:hover": {
      background: Color.buttonBlack,
    },

    "&.Mui-disabled": {
      color: Color.white,
      opacity: 0.38,
    },

    [props.theme.breakpoints.up(768)]: {
      fontSize: "1rem",
      marginTop: "0px",
    },

    [props.theme.breakpoints.down(768)]: {
      width: "129px",
      paddingInline: "0",
    },
  })
);
