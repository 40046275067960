import { datadogRum } from "@datadog/browser-rum";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { months, STOP_SYNC_LOGIN } from "../../helpers/constant";
import {
  generateDeviceType,
  removeItem,
  setItem,
} from "../../helpers/data_management";
import { escapeDoubleQuoteFromPayload } from "../../helpers/escapeDoubleQuoteFromPayload";
import { setCountryApi } from "../../helpers/multicountry_management";
import { sendTrackingEvent } from "../../helpers/tracking_management";
import {
  clearJobAlerts,
  updateJobListFilter,
} from "../../redux/actions/job_action";
import api from "../../utils/api";
import * as types from "../types/user_type";
import { updateAshleyEditDialog } from "./dialog_action";

const userAttributesForV1TalentAvailable = `
  activelyAvailable
  age
  approveAt
  approved
  birthDate
  createdAt
  currentCompany
  currentLocation
  cvDrop
  educationalInstitution
  email
  expectedSalary
  experienceIds
  fieldOfStudy
  freshGrad
  gender
  graduationYear
  highestEducationLevel
  id
  jobTypeIds
  major
  mobileNumber
  name
  nationality
  onboardingComplete
  profileImage
  profileImageSquare
  resume
  shortSummary
  signupPlatformDeviceOs
  state
  stateRegionNames
  token
  trackIds
  uploadResumeAt
  videoCv
  videoCvOptimized
  videoCvOptimizedExists
  videoCvThumbnail
`;

const newUserAttributes = `
  globalId
  abTest
  activelyAvailable
  activeCountries
  age
  approveAt
  approved
  autoGeneratedResume
  birthDate
  certificateAndTrainings {
    nodes {
      completionYear
      createdAt
      credentialUrl
      id
      issuingOrganization
      title
      updatedAt
    }
    totalCount
  }
  createdAt
  currentLocation
  cvDrop
  educations {
    nodes {
      createdAt
      description
      educationLevel
      educationalInstitution
      fieldOfStudy
      graduationYear
      id
      updatedAt
    }
    totalCount
  }
  email
  expectedSalary
  experienceIds
  extracurriculars {
    totalCount
    nodes {
      completionYear
      createdAt
      description
      id
      title
      updatedAt
    }
  }
  finishedWorkPersona
  firstName
  freshGrad
  gender
  hasMobile
  honorAndAwards {
    nodes {
      completionYear
      createdAt
      description
      id
      title
      updatedAt
    }
    totalCount
  }
  id
  industryIds
  jobTypeIds
  languages
  mobileNumber
  name
  nationality
  onboardingComplete
  pdpaAccepted
  profileImage
  profileImageSquare
  projectAndPortfolios {
    nodes {
      completionYear
      createdAt
      description
      id
      projectUrl
      title
      updatedAt
    }
    totalCount
  }
  relocationPreferences
  rightToWork
  resume
  resetPasswordAt
  shortSummary
  signupPlatformDeviceOs
  skills
  state
  stateRegionNames
  talentSearchCountries
  token
  trackIds
  uploadResumeAt
  videoCv
  videoCvOptimized
  videoCvOptimizedExists
  videoCvOriginal
  videoCvThumbnail
  websiteUrl
  websiteUrlTitle
  workingExperiences {
    nodes {
      companyName
      createdAt
      currentWork
      description
      endDateMonth
      endDateYear
      id
      industry {
        id 
        title
      }
      jobTitle
      specialization
      startDateMonth
      startDateYear
      updatedAt
    }
    totalCount
  }
  workPersona
`;

const login =
  (params = {}) =>
  async (dispatch, getState) => {
    if (params.email == undefined || params.email == null) {
      return;
    }

    let userEmail = { email: params.email };

    const payload = {
      query: `mutation {
        login(
          input: {email: "${params.email}", password:"${params.password}", deviceType: "web"}
        ) {
          user {
            ${newUserAttributes}
              }
              token
            }
      }
      `,
    };

    dispatch({ type: types.LOGGING_USER_IN });

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const user =
            response.data?.data?.login != null
              ? response.data?.data?.login?.user
              : null;

          const { message } =
            response.data?.errors && response.data?.errors?.length > 0
              ? response.data?.errors[0]
              : {};

          const token = response.data?.data?.login?.token;

          if ((message === null || message === undefined) && user) {
            Cookies.set("token", token);

            Cookies.set(process.env.NEXT_PUBLIC_GLOBAL_ID, user?.globalId, {
              // make sure this is dot hiredly dot com because we need all subdomain to access this value.
              domain: ".hiredly.com",
              path: "/",
              expires: 7,
            });

            Cookies.set(process.env.NEXT_PUBLIC_JWT_COOKIE, token, {
              // make sure this is dot hiredly dot com because we need all subdomain to access this value.
              domain: ".hiredly.com",
              path: "/",
              expires: 7,
            });
            setItem(types.USER_TOKEN, token);
            setItem(types.USER_PROFILE, JSON.stringify(user));
            datadogRum.setUser({
              id: user?.id,
              email: user?.email,
              name: user?.name,
            });

            return dispatch({
              type: types.NEW_SESSION_LOGIN_SUCCEED,
              payload: {
                user: user,
                workingExp: user.workingExperiences,
                educations: user.educations,
                honorAndAward: user.honorAndAwards,
                extracurricular: user.extracurriculars,
                projects: user.projectAndPortfolios,
                certAndTraining: user.certificateAndTrainings,
                language: user.languages,
                skills: user.skills,
              },
            });
          }

          if (message) {
            if (response.data?.errors) {
              return dispatch({
                type: types.SESSION_LOGIN_FAILED,
                invalid: true,
              });
            }

            dispatch({
              type: types.REDIRECT_NEW_EMAIL_SIGNUP,
              payload: { userEmail },
            });

            return dispatch({
              type: types.SESSION_LOGIN_FAILED_INVALID_EMAIL,
              invalid: true,
            });
          }
        }
        return dispatch({ type: types.SESSION_LOGIN_FAILED });
      })
      .catch((error) => {
        console.error("login_failed:", error);
        return dispatch({ type: types.SESSION_LOGIN_FAILED });
      });
  };

const globalLoginFunc =
  (params = {}) =>
  async (dispatch) => {
    const deviceType = generateDeviceType();

    const payload = {
      query: `
      mutation {
        globalLogin(input: { email: "${params.email}", password: "${params.password}", deviceType: "${deviceType}" }) {
          token
          user {
            ${newUserAttributes}
          }
        }
      }
      `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status !== 200) {
      return dispatch({ type: types.SESSION_LOGIN_FAILED });
    }

    const { token, user } = response?.data?.data?.globalLogin || {};

    const { message } = Array.isArray(response?.data?.data?.errors)
      ? response?.data?.data?.errors[0]
      : {};

    // if there is any error message, no token or no user object
    if (message || !token || !user) {
      return dispatch({
        type: types.SESSION_LOGIN_FAILED,
        invalid: true,
      });
    }

    if (!message && user && token) {
      Cookies.set(process.env.NEXT_PUBLIC_JWT_COOKIE, token, {
        // make sure this is dot hiredly dot com because we need all subdomain to access this value.
        domain: ".hiredly.com",
        path: "/",
        expires: 7,
      });

      Cookies.set(process.env.NEXT_PUBLIC_GLOBAL_ID, user?.globalId, {
        // make sure this is dot hiredly dot com because we need all subdomain to access this value.
        domain: ".hiredly.com",
        path: "/",
        expires: 7,
      });

      Cookies.remove(process.env.NEXT_PUBLIC_LOGOUT_FLAG, {
        domain: ".hiredly.com",
        path: "/",
      });

      setItem(types.USER_TOKEN, token);
      setItem(types.USER_PROFILE, JSON.stringify(user));

      datadogRum.setUser({
        id: user?.id,
        email: user?.email,
        name: user?.name,
      });

      posthog.identify(user?.id, {
        uuid: user.id,
        age: parseInt(user?.age ?? 0),
        gender: user?.gender,
        nationality: user?.nationality,
        is_actively_available: user?.activelyAvailable,
        has_resume: user?.resume ? true : false,
        current_location: user?.currentLocation,
        is_fresh_grad: user?.freshGrad,
        is_onboarding_completed: user?.onboardingComplete,
        signup_platform_device_os: user?.signupPlatformDeviceOs,
        track_ids: user?.trackIds,
        // year_of_experience: workExpYearDiff ?? 0,
        name: user?.name,
        email: user?.email,
      });

      dispatch({
        type: types.FETCH_PERSONA_SUCCESS,
        payload: user?.workPersona ?? {},
      });

      return dispatch({
        type: types.NEW_SESSION_LOGIN_SUCCEED,
        payload: {
          user: user,
          workingExp: user.workingExperiences,
          educations: user.educations,
          honorAndAward: user.honorAndAwards,
          extracurricular: user.extracurriculars,
          projects: user.projectAndPortfolios,
          certAndTraining: user.certificateAndTrainings,
          language: user.languages,
          skills: user.skills,
        },
      });
    }
  };

const logout = () => async (dispatch, getState) => {
  posthog.reset();
  removeItem(types.USER_TOKEN);
  removeItem(types.USER_PROFILE);
  removeItem("resume-approved");

  // Cookies.set(process.env.NEXT_PUBLIC_JWT_COOKIE, null, {
  //   domain: ".hiredly.com",
  //   path: "/",
  //   expires: 7,
  // });
  Cookies.remove(process.env.NEXT_PUBLIC_JWT_COOKIE, {
    domain: ".hiredly.com",
    path: "/",
  });

  // Cookies.set(process.env.NEXT_PUBLIC_GLOBAL_ID, null, {
  //   domain: ".hiredly.com",
  //   path: "/",
  //   expires: 7,
  // });
  Cookies.remove(process.env.NEXT_PUBLIC_GLOBAL_ID, {
    domain: ".hiredly.com",
    path: "/",
    expires: 7,
  });

  Cookies.remove(STOP_SYNC_LOGIN);

  Cookies.set(process.env.NEXT_PUBLIC_LOGOUT_FLAG, true, {
    domain: ".hiredly.com",
    path: "/",
  });

  dispatch(updateJobListFilter({}));
  dispatch(clearJobAlerts());
  dispatch(clearProfileFormFields());

  return dispatch({ type: types.LOGOUT });
};

const register =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.REGISTERING_ACCOUNT });

    const deviceType = generateDeviceType();

    const payload = {
      query: `
        mutation {
        webRegister(input: {
          name: "${params?.name ? params?.name : ""}"
            email: "${params?.email ? params?.email : ""}"
            pdpaAccepted: ${params?.pdpaAccepted ? params?.pdpaAccepted : null}
            password: "${params?.password ? params?.password : ""}"
            bio: "${params?.bio ? params?.bio : null}"
            mediaSource: "${params?.urlParams ? params?.urlParams : ""}"
            deviceType: "${deviceType}"
            mrpParentId: "${params?.mrpParentId ? params?.mrpParentId : null}"
            nationality: "${params?.nationality ? params?.nationality : ""}"
            mobileNumber: "${params?.phone ? params?.phone : ""}",
          signupMedium: "${params?.signupMedium ? params?.signupMedium : ""}",
          signupSource: "${params?.signupSource ? params?.signupSource : ""}",
          }) {
            user {
              ${newUserAttributes}
            }
            newUser
            token
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data && response.data?.data?.webRegister != null) {
            let newUser = response.data?.data?.webRegister?.newUser;
            let user = response.data?.data?.webRegister?.user;
            const globalToken = response?.data?.data?.webRegister?.token;

            if (newUser) {
              setItem(types.USER_TOKEN, globalToken);
              setItem(types.USER_PROFILE, JSON.stringify(user));

              Cookies.set(process.env.NEXT_PUBLIC_JWT_COOKIE, globalToken, {
                // make sure this is dot hiredly dot com because we need all subdomain to access this value.
                domain: ".hiredly.com",
                path: "/",
                expires: 7,
              });

              Cookies.set(process.env.NEXT_PUBLIC_GLOBAL_ID, user?.globalId, {
                // make sure this is dot hiredly dot com because we need all subdomain to access this value.
                domain: ".hiredly.com",
                path: "/",
                expires: 7,
              });

              Cookies.remove(process.env.NEXT_PUBLIC_LOGOUT_FLAG, {
                domain: ".hiredly.com",
                path: "/",
              });

              posthog.identify(user?.id, {
                name: user?.name,
                email: user?.email,
              });

              return dispatch({
                type: types.REGISTER_ACCOUNT_SUCCEED,
                user: user,
              });
            } else {
              return dispatch({
                type: types.REGISTER_ACCOUNT_FAILED,
                message: "account exist",
              });
            }
          } else {
            return dispatch({
              type: types.REGISTER_ACCOUNT_FAILED,
            });
          }
        }
        return { type: types.REGISTER_ACCOUNT_FAILED };
      });
  };

/*
 * Params:
 * 1) provider
 * 2) accessToken
 */
const socialMediaLogin =
  (params = {}) =>
  (dispatch) => {
    // Custom action for datadog RUM
    datadogRum.addAction("socialMediaLogin", {
      provider: "Google",
      // can add on any value you want
    });

    dispatch({ type: types.SOCIAL_LOGIN });

    const deviceType = generateDeviceType();

    const payload = {
      query: `
      mutation {
  socialMediaLogin(input: {
    deviceType: "${deviceType}",
    provider: "${params.provider}",
    accessToken: "${params.accessToken}",
    mediaSource: "${params.urlParams}",
    bio: "${params.referralToken ? params.referralToken : null}",
    signupMedium: "#{signup_medium}",
    signupSource: "#{signup_source}",
  }) {
          user {
            globalId
            ${newUserAttributes}
          }     
          newUser    
        }
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.socialMediaLogin) {
            const { user, newUser } = response.data?.data?.socialMediaLogin;

            if (user === null && newUser === null) {
              return dispatch({
                type: types.SOCIAL_LOGIN_FAILED,
                payload: {
                  noEmail: true,
                },
              });
            }

            if (user != null) {
              Cookies.remove(process.env.NEXT_PUBLIC_LOGOUT_FLAG, {
                domain: ".hiredly.com",
                path: "/",
              });

              Cookies.set(process.env.NEXT_PUBLIC_JWT_COOKIE, user?.token, {
                // make sure this is dot hiredly dot com because we need all subdomain to access this value.
                domain: ".hiredly.com",
                path: "/",
                expires: 7,
              });

              Cookies.set(process.env.NEXT_PUBLIC_GLOBAL_ID, user?.globalId, {
                // make sure this is dot hiredly dot com because we need all subdomain to access this value.
                domain: ".hiredly.com",
                path: "/",
                expires: 7,
              });

              setItem(types.USER_TOKEN, user.token);
              setItem(types.USER_PROFILE, JSON.stringify(user));

              datadogRum.setUser({
                id: user?.id,
                email: user?.email,
                name: user?.name,
              });

              posthog.identify(user?.id, {
                uuid: user.id,
                age: parseInt(user?.age ?? 0),
                gender: user?.gender,
                nationality: user?.nationality,
                is_actively_available: user?.activelyAvailable,
                has_resume: user?.resume ? true : false,
                current_location: user?.currentLocation,
                is_fresh_grad: user?.freshGrad,
                is_onboarding_completed: user?.onboardingComplete,
                signup_platform_device_os: user?.signupPlatformDeviceOs,
                track_ids: user?.trackIds,
                // year_of_experience: workExpYearDiff ?? 0,
                name: user?.name,
                email: user?.email,
              });

              return dispatch({
                type: types.SOCIAL_LOGIN_SUCCEED,
                payload: { user, newUser },
              });
            }
          }

          if (response.data?.errors?.length > 0) {
            const params = {
              message:
                response.data?.errors[0]?.message ?? "Social Login Failed",
            };
            return dispatch({
              type: types.SOCIAL_LOGIN_FAILED,
              payload: params,
            });
          }
        }
        return dispatch({
          type: types.SOCIAL_LOGIN_FAILED,
          payload: { message: "Sorry there was a problem with your request" },
        });
      });
  };

const convertArrayToProperString = (infos) => {
  if (!infos) return;

  let convertedValue = "[";

  infos.map((info) => {
    convertedValue += "{";

    Object.keys(info).map((key, index) => {
      const value = info[key];

      if (index > 0) {
        convertedValue += ",";
      }

      convertedValue += `${key} : `;

      if (typeof value === "string" && key != "industryId") {
        convertedValue += `"${value}"`;
      } else {
        convertedValue += `${value} `;
      }
    });

    convertedValue += "}";
  });

  convertedValue += "]";

  return convertedValue;
};

const updateOnboardingInfo =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.UPDATE_ONBOARDING_INFO });

    const nameInput = params.name ? `name: "${params.name}",` : "";
    const mobileNumberInput = params.mobileNumber
      ? `mobileNumber: "${params.mobileNumber}",`
      : "";
    const nationalityInput = params.nationality
      ? `nationality: "${params.nationality}",`
      : "";
    const currentLocationInput = params.currentLocation
      ? `currentLocation: "${params.currentLocation}",`
      : "";
    const freshGradInput =
      params.freshGrad !== undefined ? `freshGrad: ${params.freshGrad},` : "";
    const experienceInput = params.workingExperiences
      ? `workingExperiences: ${convertArrayToProperString(
          params.workingExperiences
        )},`
      : "";
    const educationInput = params.educations
      ? `educations: ${convertArrayToProperString(params.educations)},`
      : "";
    const skillInput = params.skills
      ? `skills: ${convertArrayToProperString(params.skills)},`
      : "";
    const talentSearchInput =
      params.talentSearch !== undefined
        ? `activelyAvailable: ${params.talentSearch}`
        : "";

    let allInput =
      nameInput +
      mobileNumberInput +
      nationalityInput +
      currentLocationInput +
      freshGradInput +
      experienceInput +
      educationInput +
      skillInput +
      talentSearchInput;

    if (allInput.charAt(allInput.length - 1) == ",") {
      allInput = allInput.substring(0, allInput.length - 1);
    }

    const payload = {
      query: `
        mutation {
  onboarding(input: {
    ${allInput}            
          }) {
            user {
              ${newUserAttributes}
  }
}
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.data && response.data.data.onboarding != null) {
            let user = response.data.data.onboarding.user;

            if (user) {
              setItem(types.USER_PROFILE, JSON.stringify(user));
              dispatch({
                type: types.UPDATE_USER,
                user: user,
              });

              return dispatch({
                type: types.UPDATE_ONBOARDING_INFO_SUCCEED,
                user: user,
              });
            }
          }
          return dispatch({
            type: types.UPDATE_ONBOARDING_INFO_FAILED,
          });
        }
        return { type: types.UPDATE_ONBOARDING_INFO_FAILED };
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

const updateTalentAvailable =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.UPDATING_TALENT_AVAILABLE });

    const payload = {
      query: `
        mutation {
  updateTalentAvailable(input: { activelyAvailable: ${params.status} }) {
            user {
              ${userAttributesForV1TalentAvailable}
  }
  success
}
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (
            response.data?.data &&
            response.data?.data?.updateTalentAvailable != null
          ) {
            const { user } = response?.data?.data?.updateTalentAvailable || {};

            // posthog.capture("user_profile_update", {
            //   distinctId: user?.id,

            //   $set: {
            //     age: parseInt(user?.age ?? 0),
            //     gender: user?.gender,
            //     nationality: user?.nationality,
            //     is_actively_available: user?.activelyAvailable,
            //     has_resume: user?.resume ? true : false,
            //     current_location: user?.currentLocation,
            //     is_fresh_grad: user?.freshGrad,
            //     is_onboarding_completed: user?.onboardingComplete,
            //     signup_platform_device_os: user?.signupPlatformDeviceOs,
            //     track_ids: user?.trackIds,
            //     name: user?.name,
            //     email: user?.email,
            //   },
            // });

            return dispatch({
              type: types.UPDATE_TALENT_AVAILABLE_SUCCEED,
              user: response.data?.data?.updateTalentAvailable?.user,
            });
          } else {
            return dispatch({
              type: types.UPDATE_TALENT_AVAILABLE_FAILED,
            });
          }
        }
        return { type: types.UPDATE_TALENT_AVAILABLE_FAILED };
      });
  };

// Update profile form fields
const updateProfileFormFields = (params) => async (dispatch, getState) => {
  const formFields = params;
  return dispatch({
    type: types.UPDATE_PROFILE_FIELDS,
    payload: formFields,
  });
};

// Clear profile form fields
const clearProfileFormFields = (params) => async (dispatch, getState) => {
  return dispatch({
    type: types.CLEAR_PROFILE_FIELDS,
  });
};

const updateResumeDrop =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE });
    if (params.origin == "dropResume") {
      sendTrackingEvent({
        event: "drop-resume",
      });
    }

    const payload = {
      query: `
        mutation {
  saveProfile(input: { cvDrop: ${params.resume_drop}}) {
            user {
              ${userAttributesForV1TalentAvailable}
  }
}
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (
            response.data &&
            response.data?.data &&
            response.data?.data?.updateProfile
          ) {
            return dispatch({
              type: types.UPDATE_USER_PROFILE_SUCCEED,
              user: response.data?.data?.updateProfile?.user,
            });
          } else {
            let errorMessage = "";
            if (response.data?.errors?.length > 0) {
              if (response.data?.errors[0]?.message) {
                errorMessage = response.data?.errors[0]?.message;
              }
            }
            return dispatch({
              type: types.UPDATE_USER_PROFILE_FAILED,
              errorMessage,
            });
          }
        }
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      });
  };

const newGetUserInfo =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_USER_INFO });
    const payload = {
      query: `{
        me {
          globalId
          ${newUserAttributes}
        }
      }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { me } = response.data?.data || {};

          // in V1 (new) api, we longer check if me != null, as there will be dedicated error messages
          const { message } =
            response.data?.errors &&
            Array.isArray(response.data?.errors) &&
            response.data?.errors?.length > 0
              ? response.data?.errors[0]
              : {};

          if ((message === null || message === undefined) && me) {
            setItem(types.USER_PROFILE, JSON.stringify(me));

            // Get the local storage resume-approved value
            const localStorageApproved =
              localStorage.getItem("resume-approved");

            // if local storage value and value from response.body is different:
            // 1. trigger clevertap event
            // 2. THEN, update local storage value
            if (!localStorageApproved || localStorageApproved !== me.approved) {
              // update local storage value
              localStorage.setItem("resume-approved", me.approved);
            }

            dispatch({
              type: types.FETCH_PERSONA_SUCCESS,
              payload: me?.workPersona ?? {},
            });
            const countryPreference = {
              relocationPreferences: me.relocationPreferences,
              rightToWork: me.rightToWork,
              talentSearchCountries: me.talentSearchCountries,
            };

            return dispatch({
              type: types.FETCH_NEW_USER_INFO_SUCCEED,
              payload: {
                mobileNumber: me.mobileNumber,
                nationality: me.nationality,
                user: me,
                workingExp: me.workingExperiences,
                educations: me.educations,
                honorAndAward: me.honorAndAwards,
                extracurricular: me.extracurriculars,
                projects: me.projectAndPortfolios,
                certAndTraining: me.certificateAndTrainings,
                language: me.languages,
                skills: me.skills,
                countryPreference: countryPreference,
              },
            });
          }
        }

        if (response.status === 400) {
          if (
            response.error &&
            response.error?.response?.data?.errors[0].includes(
              "Account deleted"
            )
          ) {
            removeItem(types.USER_TOKEN);
            removeItem(types.USER_PROFILE);

            dispatch(updateJobListFilter({}));
            dispatch(clearJobAlerts());
            dispatch(clearProfileFormFields());

            return dispatch({ type: types.DELETED_USER });
          }
        }

        return dispatch({ type: types.FETCH_USER_INFO_FAILED });
      });
  };

// const getUserWorkPersona =
//   (params = {}) =>
//   async (dispatch, getState) => {
//     dispatch({ type: types.FETCHING_USER_WORK_PERSONA });

//     const payload = {
//       query: `{
//         workPersonas(filters: { personaId: { eq: "${params.personaId}" } }) {
//           data {
//             attributes {
//               name
//               oneLiner
//               pillarCombination
//               summary
//               avatarImg {
//                 data {
//                   attributes {
//                     url
//                   }
//                 }
//               }
//               valueChart {
//                 data {
//                   attributes {
//                     url
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//       `,
//     };

//     const response = await strapiCall(payload, true);

//     if (response.status === 200) {
//       const { data } = response.data?.data?.workPersonas || [];
//       if (data) {
//         const workPersona = data[0].attributes;
//         dispatch({
//           type: types.FETCH_USER_WORK_PERSONA_SUCCEED,
//           payload: workPersona,
//         });
//       }
//     }
//   };

const getCountryPreferences =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCHING_COUNTRY_PREFERENCE });
    const payload = {
      query: `{
        me {
          relocationPreferences
          rightToWork
          talentSearchCountries
        }
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { me } = response?.data?.data || {};

          if (me) {
            return dispatch({
              type: types.FETCH_COUNTRY_PREFERENCE_SUCCEED,
              payload: { countryPreference: me },
            });
          } else {
            return dispatch({
              type: types.FETCH_COUNTRY_PREFERENCE_FAILED,
            });
          }
        }
        return dispatch({ type: types.FETCH_COUNTRY_PREFERENCE_FAILED });
      });
  };

const updateCountryPreference =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.UPDATING_COUNTRY_PREFERENCE });

    const country = `country: "${params.country}"`;
    const remote = `remote: ${params.remote}`;
    const relocate = `relocate: ${params.relocate}`;
    const openToSponsor = `openToSponsor:${params.openToSponsor}`;
    const visaOrEmploymentPass = `visaOrEmploymentPass: ${params.visaOrEmploymentPass}`;
    const localCitizen = `localCitizen: ${params.localCitizen}`;

    const queryInput = `{
        ${country}
        ${remote}
        ${relocate}
        ${openToSponsor}
        ${visaOrEmploymentPass}
        ${localCitizen}
      }`;

    const payload = {
      query: `mutation {
        saveCountryPreference(
          input: {workArrangements: [${queryInput}]}
        ) {
          user {        
              relocationPreferences
              rightToWork
              talentSearchCountries
            }
          success
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user, success } =
            response?.data?.data?.saveCountryPreference || {};

          const userData = getUser();
          const cUserData = {
            ...userData,
            relocationPreferences: user?.relocationPreferences,
            rightToWork: user?.rightToWork,
            talentSearchCountries: user?.talentSearchCountries,
          };

          if (success) {
            setItem(types.USER_PROFILE, JSON.stringify(cUserData));

            return dispatch({
              type: types.UPDATE_COUNTRY_PREFERENCE_SUCCEED,
              payload: { countryPreference: user },
            });
          } else {
            return dispatch({
              type: types.UPDATE_COUNTRY_PREFERENCE_FAILED,
            });
          }
        }
        return dispatch({ type: types.UPDATE_COUNTRY_PREFERENCE_FAILED });
      });
  };

const updateTalentSearch =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.UPDATING_TALENT_SEARCH });

    const payload = {
      query: `mutation {
        saveCountryPreference(
          input: {talentSearchCountries: ${JSON.stringify(
            params.talentSearchCountries
          )}}
        ) {
          user {        
            globalId
            ${newUserAttributes}
          }
          success
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user, success } =
            response?.data?.data?.saveCountryPreference || {};

          if (success) {
            setItem(types.USER_PROFILE, JSON.stringify(user));

            return dispatch({
              type: types.UPDATE_TALENT_SEARCH_SUCCEED,
              payload: { countryPreference: user },
            });
          } else {
            return dispatch({
              type: types.UPDATE_TALENT_SEARCH_FAILED,
            });
          }
        }
        return dispatch({ type: types.UPDATE_TALENT_SEARCH_FAILED });
      });
  };

const profileImageUpload =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE_IMAGE });

    if (params.origin == "onboarding") {
      sendTrackingEvent({
        event: "upload-profile-image-onboarding",
      });
    } else {
      sendTrackingEvent({
        event: "upload-profile-image-profile",
      });
    }

    const payload = {
      query: `
        mutation {
          saveProfile(input: {profileImage: "${params.profileImage}"}) {
            user {
              ${newUserAttributes}
            }
          }
        }
      `,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveProfile) {
            const { user } = response.data?.data?.saveProfile;
            if (user != null) {
              return dispatch({
                type: types.UPDATE_USER_PROFILE_IMAGE_SUCCEED,
                user: user,
              });
            }
            if (user === null) {
              return dispatch({ type: types.UPDATE_USER_PROFILE_IMAGE_FAILED });
            }
          }
        }
        return dispatch({ type: types.UPDATE_USER_PROFILE_IMAGE_FAILED });
      });
  };

const newResumeUpload =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_CV });
    const newCvDropInput = params.cv_drop ? true : false;

    const autoGeneratedInput =
      params.autoGenerated === true
        ? `autoGeneratedResume: true`
        : params.autoGenerated === false
        ? `autoGeneratedResume: false`
        : "";

    const originInput = params.origin ? `origin: "${params.origin}"` : "";
    const bioInput = params.bio ? `bio: "${params.bio}"` : "";
    const mediaSource = params.media_source
      ? `mediaSource: "${params.media_source}"`
      : "";

    const newPayload = {
      query: `
      mutation {
        resumeUpload( input: {
          resume: "${params.resume}" 
          cvDrop: ${newCvDropInput} 
          ${autoGeneratedInput}
          state: "${params.state}"
          ${bioInput}
          ${originInput}
          ${mediaSource}
      }) {
          user {
            ${newUserAttributes}
          }
          message
          success
        }
      }      
      `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      newPayload
    );
    if (response?.status === 200) {
      if (response?.data?.data?.resumeUpload) {
        const { user } = response?.data?.data?.resumeUpload;
        if (user != null) {
          setItem(types.USER_PROFILE, JSON.stringify(user));

          if (params.origin == "dropCV") {
            // Tracking jobseeker resume upload & reupload on Drop Resume page.
            if (
              !params.first_time_upload_with_resume &&
              params.user_resume == ""
            ) {
              sendTrackingEvent({
                event: "first-time-upload-resume",
              });
              sendTrackingEvent({
                event: "drop-resume",
              });
            } else if (params.user_resume == "") {
              sendTrackingEvent({
                event: "drop-resume",
              });
            } else {
              sendTrackingEvent({
                event: "reupload-resume",
              });
              sendTrackingEvent({
                event: "drop-resume-updated",
              });
            }
          } else if (
            params?.origin == "drop_resume_page" ||
            params?.origin == "drop_resume_hiredlyx_page"
          ) {
            // Tracking jobseeker resume upload & reupload on onboarding, profile, and mobile profile view.
            if (!params?.cUser && params?.isLoggedIn == false) {
              // visitor && first time
              sendTrackingEvent({
                event:
                  params?.origin == "drop_resume_page"
                    ? "CE_resume_upload_first_time"
                    : "CE_resume_drop_first_time",
                origin: params?.origin,
                is_first_time: true,
                is_visitor: !params?.isLoggedIn,
              });
              posthog.capture(
                params?.origin == "drop_resume_page"
                  ? "resume_upload"
                  : "resume_drop",
                {
                  origin: params?.origin,
                  is_first_time: true,
                  is_visitor: !params?.isLoggedIn,
                }
              );
            } else if (params?.cUser?.resume == "" && params?.isLoggedIn) {
              //logged in && first time
              sendTrackingEvent({
                event:
                  params?.origin == "drop_resume_page"
                    ? "CE_resume_upload_first_time"
                    : "CE_resume_drop_first_time",
                origin: params?.origin,
                is_first_time: true,
                is_visitor: !params?.isLoggedIn,
              });
              posthog.capture(
                params?.origin == "drop_resume_page"
                  ? "resume_upload"
                  : "resume_drop",
                {
                  origin: params?.origin,
                  is_first_time: true,
                  is_visitor: !params?.isLoggedIn,
                }
              );
            } else if (params?.isLoggedIn && params?.cUser?.resume != "") {
              //logged in && reupload
              sendTrackingEvent({
                event:
                  params?.origin == "drop_resume_page"
                    ? "CE_resume_upload_reupload"
                    : "CE_resume_drop_redrop",
                origin: params?.origin,
                is_first_time: false,
                is_visitor: !params?.isLoggedIn,
              });
              posthog.capture(
                params?.origin == "drop_resume_page"
                  ? "resume_upload"
                  : "resume_drop",
                {
                  origin: params?.origin,
                  is_first_time: false,
                  is_visitor: !params?.isLoggedIn,
                }
              );
            }
          }

          return dispatch({
            type: types.UPDATE_USER_CV_SUCCEED,
            user: user,
          });
        }
        if (user === null) {
          const params_1 = {
            message: response.data?.data?.resumeUpload?.message,
          };
          return dispatch({
            type: types.UPDATE_USER_CV_FAILED,
            payload: params_1,
          });
        }
      }

      if (response.data?.errors?.length > 0) {
        const params_2 = {
          message: response.data?.errors[0]?.message,
        };

        return dispatch({
          type: types.UPDATE_USER_CV_FAILED,
          payload: params_2,
        });
      }
    }
    return dispatch({ type: types.UPDATE_USER_CV_FAILED });
  };

const fetchPresignUrl =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.FETCHING_PRESIGNED_URL });
    const payload = {
      query: `
        {
          presignUrl(filename: "${params.filename}") {
            presignedUrl
            publicUrl
          }
        }        
        `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { presignedUrl, publicUrl } =
            response?.data?.data?.presignUrl || {};

          return dispatch({
            type: types.FETCH_PRESIGNED_URL_SUCCEED,
            presign: presignedUrl,
            public: publicUrl,
          });
        } else {
          return dispatch({
            type: types.FETCH_PRESIGNED_URL_FAILED,
            presign: presignedUrl,
            public: publicUrl,
          });
        }
      });
  };

const updateVCLPublicUrl =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_VCL_PUBLIC_URL });
    const payload = {
      query: `
      mutation {
        saveProfile(input: {videoCv: "${params.publicUrl}", videoCvDeviceType: "web"}) {
          user {
            ${newUserAttributes}
          }
        }
      }
    `,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.saveProfile;
          if (user != null) {
            return dispatch({
              type: types.UPDATE_VCL_PUBLIC_URL_SUCCEED,
              payload: { me: user },
            });
          }
          if (user === null) {
            return dispatch({ type: types.UPDATE_VCL_PUBLIC_URL_FAILED });
          }
        }
        return dispatch({ type: types.UPDATE_VCL_PUBLIC_URL_FAILED });
      })
      .catch(() => {
        return dispatch({ type: types.UPDATE_VCL_PUBLIC_URL_FAILED });
      });
  };

// Update user preferences functions
const updateTrack =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE }); // For job preference use update user profile type

    const payload = {
      query: `
        mutation {
          updateTrack(input: {trackIds: [${params.trackIds}]}) {
            user {
              ${newUserAttributes}
            }
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.updateTrack;
          if (user != null) {
            // posthog.capture("user_profile_update", {
            //   distinctId: user?.id,

            //   $set: {
            //     age: parseInt(user?.age ?? 0),
            //     gender: user?.gender,
            //     nationality: user?.nationality,
            //     is_actively_available: user?.activelyAvailable,
            //     has_resume: user?.resume ? true : false,
            //     current_location: user?.currentLocation,
            //     is_fresh_grad: user?.freshGrad,
            //     is_onboarding_completed: user?.onboardingComplete,
            //     signup_platform_device_os: user?.signupPlatformDeviceOs,
            //     track_ids: user?.trackIds,
            //     name: user?.name,
            //     email: user?.email,
            //   },
            // });

            return dispatch({
              type: types.UPDATE_USER_PROFILE_SUCCEED,
              user: user,
            });
          }
          return { type: types.UPDATE_USER_PROFILE_FAILED };
        }
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      })
      .catch((error) => {
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      });
  };

const updateJobExperienceLevel =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE });

    const payload = {
      query: `
        mutation {
          updateJobExperienceLevel(input: {experienceIds: [${params.experience_ids}]}) {
            user {
              ${newUserAttributes}
            }
          }
        }
      `,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.updateJobExperienceLevel;
          if (user != null) {
            return dispatch({
              type: types.UPDATE_USER_PROFILE_SUCCEED,
              user: user,
            });
          }

          return { type: types.UPDATE_USER_PROFILE_FAILED };
        }
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      })
      .catch((error) => {
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      });
  };

const updateJobType =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE });

    const payload = {
      query: `
        mutation {
          updateJobType(input: {jobTypeIds: [${params.job_type_ids}]}) {
            user {
              ${newUserAttributes}
            }
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.updateJobType;
          if (user != null) {
            return dispatch({
              type: types.UPDATE_USER_PROFILE_SUCCEED,
              user: user,
            });
          }

          return { type: types.UPDATE_USER_PROFILE_FAILED };
        }
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      })
      .catch((error) => {
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      });
  };

const updateJobIndustry =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE });

    const payload = {
      query: `
        mutation {
          updateIndustry(input: {industryIds: [${params.job_industries_ids}]}) {
            user {
              ${newUserAttributes}
            }
            success
          }
        }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.updateIndustry;

          // posthog.capture("user_profile_update", {
          //   distinctId: user?.id,

          //   $set: {
          //     age: parseInt(user?.age ?? 0),
          //     gender: user?.gender,
          //     nationality: user?.nationality,
          //     is_actively_available: user?.activelyAvailable,
          //     has_resume: user?.resume ? true : false,
          //     current_location: user?.currentLocation,
          //     is_fresh_grad: user?.freshGrad,
          //     is_onboarding_completed: user?.onboardingComplete,
          //     signup_platform_device_os: user?.signupPlatformDeviceOs,
          //     track_ids: user?.trackIds,
          //     name: user?.name,
          //     email: user?.email,
          //   },
          // });

          if (user != null) {
            return dispatch({
              type: types.UPDATE_USER_PROFILE_SUCCEED,
              user: user,
            });
          }
          return { type: types.UPDATE_USER_PROFILE_FAILED };
        }
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      })
      .catch((error) => {
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      });
  };

const updateJobPrefSkills =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE });

    const { prefSkills } = params;

    const mappedSkills = prefSkills.map((skill) => `{ name: "${skill}" }`);

    const payload = {
      query: ` 
        mutation {
          saveSkills(input: {skills: [${mappedSkills}]}) {
            skills
            success
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { success, skills: newSkills } =
            response.data?.data?.saveSkills;
          const { errors } = response.data;

          if (success === false && errors?.length > 0) {
            return dispatch({
              type: types.UPDATE_USER_PROFILE_FAILED,
              errorMessage: errors[0]?.message,
            });
          }
          return dispatch({
            type: types.UPDATE_JOB_PREF_SKILLS_SUCCEED,
            payload: {
              newJobPrefSkills: newSkills,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.UPDATE_USER_PROFILE_FAILED,
          errorMessage: error.toString(),
        });
      });
  };

const updateStateRegion =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE });

    const payload = {
      query: `
        mutation {
          updateLocation(input: {stateRegionNames: ${JSON.stringify(
            params.state_regions
          )}}) {
            user {
              ${newUserAttributes}
            }
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.updateLocation;
          if (user != null) {
            return dispatch({
              type: types.UPDATE_USER_PROFILE_SUCCEED,
              user: user,
            });
          }

          return { type: types.UPDATE_USER_PROFILE_FAILED };
        }
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      })
      .catch((error) => {
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      });
  };

const updateExpectedSalary =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.UPDATING_USER_PROFILE });

    const payload = {
      query: `
        mutation {
          updateExpectedSalary(input: {expectedSalary: ${params.expected_salary}}) {
            user {
              ${newUserAttributes}
            }
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.updateExpectedSalary;
          if (user != null) {
            return dispatch({
              type: types.UPDATE_USER_PROFILE_SUCCEED,
              user: user,
            });
          }

          return { type: types.UPDATE_USER_PROFILE_FAILED };
        }
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      })
      .catch((error) => {
        return { type: types.UPDATE_USER_PROFILE_FAILED };
      });
  };
// END update user preferences function

// For chat
/*
 * Params:
 * 1) sb - sendbird instance
 * 2) customType - array
 * 3) refresh
 */
const fetchChatList =
  (params = {}) =>
  async (dispatch) => {
    const isOpen = params.customType.length == 1 && params.customType[0] == "2";

    if (isOpen) {
      dispatch({ type: types.FETCHING_CHAT_LIST });
    } else {
      dispatch({ type: types.FETCHING_CLOSED_CHAT_LIST });
    }

    const cParams = {
      customType: params.customType,
      refresh: params.refresh,
      isOpen: isOpen,
    };

    return params.sb
      .getGroupChannelList(cParams)
      .then((response) => {
        if (isOpen) {
          return dispatch({
            type: types.FETCH_CHAT_LIST_SUCCEED,
            chatList: response,
          });
        } else {
          return dispatch({
            type: types.FETCH_CLOSED_CHAT_LIST_SUCCEED,
            chatList: response,
          });
        }
      })
      .catch((error) => {
        if (isOpen) {
          return dispatch({
            type: types.FETCH_CHAT_LIST_FAILED,
          });
        } else {
          return dispatch({
            type: types.FETCH_CLOSED_CHAT_LIST_FAILED,
          });
        }
      });
  };

/*
 * Params:
 * 1) sb - sendbird instance
 * 2) channel
 * 3) refresh
 */
const fetchChatMessages =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_CHAT_MESSAGES });

    const cParams = {
      channel: params.channel,
      refresh: params.refresh,
    };

    return params.sb
      .getMessageList(cParams)
      .then((response) => {
        return dispatch({
          type: types.FETCH_CHAT_MESSAGES_SUCCEED,
          messages: response,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.FETCH_CHAT_MESSAGES_FAILED,
        });
      });
  };

/*
 * Params:
 * 1) sb - sendbird instance
 * 2) channel
 * 3) textMessage
 */
const sendMessage =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.SENDING_MESSAGE });

    return params.sb
      .sendMessage(params.channel, params.textMessage)
      .then((response) => {
        return dispatch({
          type: types.SEND_MESSAGE_SUCCEED,
          payload: { channel: params.channel, message: response },
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.SEND_MESSAGE_FAILED,
        });
      });
  };

const updateCurrentChat = (chat) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_CURRENT_CHAT,
        chat: chat,
      })
    );
  });
};

const addNewMessage = (message) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.ADD_NEW_MESSAGE,
        message: message,
      })
    );
  });
};

/*
 * params:
 * 1) channelUrl
 * 2) channel
 * 3) recountUnreadMessage
 */
const replaceChat =
  (params = {}) =>
  async (dispatch) => {
    return new Promise((resolve) => {
      return resolve(
        dispatch({
          type: types.REPLACING_CHAT_FROM_LIST,
          payload: {
            channelUrl: params.channelUrl,
            channel: params.channel,
            recountUnreadMessage: params.recountUnreadMessage,
          },
        })
      );
    });
  };

const countUnreadMessage = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.COUNT_UNREAD_MESSAGE,
      })
    );
  });
};
// END for chat

const updateOnboardingDialogStatus = (status) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_ONBOARDING_DIALOG_STATUS,
        status: status,
      })
    );
  });
};

const updateUser = (user) => (dispatch, getState) => {
  return dispatch({
    type: types.UPDATE_USER,
    user: user,
  });
};

const validateFullName = (params) => async (dispatch, getState) => {
  if (params) {
    return dispatch({
      type: types.VALIDATE_FULLNAME_SUCCEED,
    });
  } else {
    return dispatch({
      type: types.VALIDATE_FULLNAME_FAILED,
    });
  }
};

// For new onboarding
const updateOnboardingStatus =
  (onboardingStatus) => async (dispatch, getState) => {
    return new Promise((resolve) => {
      return resolve(
        dispatch({
          type: types.UPDATE_ONBOARDING_STATUS,
          onboardingStatus: onboardingStatus,
        })
      );
    });
  };
// End for new onboarding

// Update onboarding form fields
const updateOnboardingFormFields = (params) => async (dispatch, getState) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_ONBOARDING_FIELDS,
        payload: params,
      })
    );
  });
};

// User profile drawer index
const updateUserProfileDrawerIndex = (params) => async (dispatch, getState) => {
  return dispatch({
    type: types.UPDATE_DRAWER_INDEX,
    payload: params,
  });
};

// Update current user in profile
const updateCurrentUser = (params) => async (dispatch, getState) => {
  return dispatch({
    type: types.UPDATE_CURRENT_USER,
    payload: params,
  });
};

// To check whether user has logged in or not
const checkAuthentication = () => {
  try {
    let user = localStorage.getItem(types.USER_PROFILE);
    let userToken = localStorage.getItem(types.USER_TOKEN);

    if (Object.keys(user).length > 0 && userToken !== null) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getUser = () => {
  let user = {};
  try {
    user = JSON.parse(localStorage.getItem(types.USER_PROFILE));
  } catch (error) {
    //
  }

  return user;
};

// Change user password
const changeUserPassword =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: types.CHANGING_USER_PASSWORD,
    });

    const payload = {
      query: `
        mutation {
          changePassword(input: {password: "${params.password}", passwordConfirmation: "${params.passwordConfirmation}"}) {
            success
            user {
              ${newUserAttributes}
            }
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user, success } = response.data?.data?.changePassword;
          if (user != null && success) {
            return dispatch({
              type: types.CHANGING_USER_PASSWORD_SUCCEED,
              user: user,
            });
          }
          return { type: types.CHANGING_USER_PASSWORD_FAILED };
        }
        return { type: types.CHANGING_USER_PASSWORD_FAILED };
      })
      .catch((error) => {
        return { type: types.CHANGING_USER_PASSWORD_FAILED };
      });
  };

// Forgot user password
const forgotPassword =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: types.FORGETTING_PASSWORD,
    });

    const payload = {
      query: `
        mutation {
          forgotPassword(input: {email: "${params.email}"}) {
            result       
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { result } = response.data?.data?.forgotPassword;
          if (result) {
            return dispatch({
              type: types.FORGET_PASSWORD_SUCCEED,
              result: result,
            });
          } else {
            return dispatch({
              type: types.FORGET_PASSWORD_FAILED,
              result: result,
            });
          }
        }
        return { type: types.FORGET_PASSWORD_FAILED };
      })
      .catch((error) => {
        return { type: types.FORGET_PASSWORD_FAILED };
      });
  };

// Reset user password
const resetPassword =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: types.RESETTING_PASSWORD,
    });

    const payload = {
      query: `
        mutation {
          resetPassword(input: {password: "${params.password}", token: "${params.token}"}) {
            result
            message
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { result, message } = response.data?.data?.resetPassword;
          if (result) {
            return dispatch({
              type: types.RESET_PASSWORD_SUCCEED,
              result: result,
              message: message,
            });
          } else {
            return dispatch({
              type: types.RESET_PASSWORD_FAILED,
              result: result,
              message: message,
            });
          }
        }
        return { type: types.RESET_PASSWORD_FAILED };
      })
      .catch((error) => {
        return { type: types.RESET_PASSWORD_FAILED };
      });
  };

const submitContactUs =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: types.SUBMITTING_CONTACT_US,
    });

    let nameInput = params.name ? `name: "${params.name}",` : "";
    let companyNameInput = params.companyName
      ? `companyName: "${params.companyName}",`
      : "";
    let emailInput = params.email ? `email: "${params.email}",` : "";
    let phoneInput = params.phone ? `phoneNumber: "${params.phone}",` : "";
    let subjectInput = params.subject ? `subject: "${params.subject}",` : "";
    let messageInput = params.message ? `message: "${params.message}",` : "";
    let originInput = params.origin ? `origin: "${params.origin.trim()}",` : "";
    let servicesInput = params.services
      ? `serviceInterested: ${JSON.stringify(params.services)},`
      : "";
    let heardFromInput = params.heardFrom
      ? `heardFrom: "${params.heardFrom}"`
      : "";
    let trackingParameters = params.trackingParameters
      ? `trackingParameters: "${params.trackingParameters}"`
      : "";

    let queryInput =
      nameInput +
      companyNameInput +
      emailInput +
      phoneInput +
      subjectInput +
      messageInput +
      originInput +
      heardFromInput +
      trackingParameters +
      servicesInput;

    // Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    const payload = {
      query: `
        mutation {
          sendEnquiryEmail(
            input: {${queryInput}}
          ) {
            enquiryEmailSent
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { enquiryEmailSent } = response.data?.data?.sendEnquiryEmail;

          if (enquiryEmailSent) {
            return dispatch({
              type: types.SUBMIT_CONTACT_US_SUCCEED,
            });
          }
        }

        return dispatch({
          type: types.SUBMIT_CONTACT_US_FAILED,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.SUBMIT_CONTACT_US_FAILED,
        });
      });
  };

// User Profile Skills section
const fetchingSkillList =
  (queryString = String, isCert) =>
  async (dispatch, getState) => {
    // only trigger Lightcast api when input length is 3 or above
    if (queryString.length >= 3) {
      const lightCastToken = localStorage.getItem("lightcast-token");
      const client = axios.create({
        baseURL: "https://emsiservices.com",
        headers: { Authorization: `Bearer ${lightCastToken}` },
      });
      const path = "/skills/versions/latest/skills";

      const typeId = isCert ? "ST3" : "ST1,ST2";

      const requestParams = new URLSearchParams();
      requestParams.append("q", queryString);
      requestParams.append("typeIds", typeId);
      requestParams.append("limit", 10);

      client
        .get(path, { params: requestParams })
        .then((response) => {
          if (
            response.data?.data !== null ||
            response.data?.data !== undefined
          ) {
            // If no result from lightcast, append user input as option
            if (response.data?.data?.length < 1) {
              dispatch({
                type: types.UPDATE_LIGHTCAST_SKILLS_LIST,
                payload: {
                  suggestSkills: [queryString],
                  userInput: queryString,
                },
              });

              dispatch({
                type: types.UPDATE_LIGHTCAST_CERTIFICATES_LIST,
                payload: {
                  suggestSkills: obtainedSkills,
                  userInput: queryString,
                },
              });
            } else {
              let obtainedSkills = response.data?.data?.map(
                (skill) => skill.name
              );

              dispatch({
                type: types.UPDATE_LIGHTCAST_SKILLS_LIST,
                payload: {
                  suggestSkills: obtainedSkills,
                  userInput: queryString,
                },
              });

              dispatch({
                type: types.UPDATE_LIGHTCAST_CERTIFICATES_LIST,
                payload: {
                  suggestSkills: obtainedSkills,
                  userInput: queryString,
                },
              });
            }
          }
        })
        .catch((error) => {
          console.error("failed_fetching_skills:", error);
        });
    }
  };

const addSkill =
  (inputSkill = String) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_USER_SKILLS_LIST,
      payload: {
        skill: inputSkill,
      },
    });

    dispatch({
      type: types.RESET_LIGHTCAST_SKILLS_LIST,
    });
  };

const resetLightcastSkills = () => async (dispatch, getState) => {
  dispatch({
    type: types.RESET_LIGHTCAST_SKILLS_LIST,
  });
};

const removeSkill =
  (inputSkill = String) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.REMOVE_USER_SKILL_FROM_LIST,
      payload: {
        skill: inputSkill,
      },
    });
  };

const saveUserSkills =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.SAVING_USER_SKILLS });

    const { latestSkills } = params;

    const mappedSkills = latestSkills.map((skill) => `{ name: "${skill}" }`);

    const payload = {
      query: ` mutation {
        saveSkills(input: {skills: [${mappedSkills}]}) {
          skills
          success
        }
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { success, skills: newSkills } =
            response.data?.data?.saveSkills;
          const { errors } = response.data;

          if (success === false && errors?.length > 0) {
            return dispatch({
              type: types.UPDATE_USER_SKILL_FAILED,
              errorMessage: errors[0]?.message,
            });
          }
          return dispatch({
            type: types.SAVE_USER_SKILLS_STATUS,
            payload: {
              stillSaving: !success,
              skills: newSkills,
            },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.UPDATE_USER_SKILL_FAILED,
          errorMessage: error.toString(),
        });
      });
  };

// User profile certificates section
const createCertificates =
  (params = {}, isEdit) =>
  async (dispatch, getState) => {
    let payload = {};
    if (isEdit) {
      payload = {
        query: `mutation {
          saveCertificateAndTrainings(
            input: {
              certificateAndTrainings: [
                {
                  id: "${params.id}"
                  title: "${params.title}",
                  issuingOrganization: "${params.issuing_organization}",
                  completionYear: ${params.completion_year},
                  credentialUrl: "${params.credential_url}",
                }
              ]
            }
          ) {
            success
            certificateAndTrainings {
              id
              title
              issuingOrganization
              credentialUrl
              completionYear 
            }
          }
        }`,
      };
    } else {
      payload = {
        query: `mutation {
          saveCertificateAndTrainings(
            input: {
              certificateAndTrainings: [
                {
                  title: "${params.title}",
                  issuingOrganization: "${params.issuing_organization}",
                  completionYear: ${params.completion_year},
                  credentialUrl: "${params.credential_url}",
                }
              ]
            }
          ) {
            success
            certificateAndTrainings {
              id
              title
              issuingOrganization
              credentialUrl
              completionYear 
            }
          }
        }`,
      };
    }

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveCertificateAndTrainings?.success) {
            const { certificateAndTrainings } =
              response.data?.data?.saveCertificateAndTrainings;

            // Get response data to payload
            dispatch({
              type: types.UPDATE_CERTIFICATES,
              payload: {
                newCertificate: { nodes: certificateAndTrainings },
              },
            });

            // When success
            dispatch({
              type: types.UPDATE_CERTIFICATES_SUCCEED,
              payload: {
                createStatus: true,
              },
            });
          } else {
            return dispatch({
              type: types.UPDATE_CERTIFICATES_FAILED,
              payload: {
                createStatus: false,
                errorMessage: response?.data?.errors[0]?.message,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_CERTIFICATES_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      });
  };

const deleteCertificates =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `mutation {
        saveCertificateAndTrainings(
          input: {
            certificateAndTrainings: [
              {
                id: "${params.id}"
                _destroy: true
              }
            ]
          }
        ) {
          success
          certificateAndTrainings {
            id
            title
            issuingOrganization
            credentialUrl
            completionYear 
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveCertificateAndTrainings?.success) {
            const { certificateAndTrainings } =
              response.data?.data?.saveCertificateAndTrainings;

            // Get response data to payload
            dispatch({
              type: types.DELETE_CERTIFICATES,
              payload: {
                newCertificate: { nodes: certificateAndTrainings },
              },
            });

            dispatch({
              type: types.UPDATE_CERTIFICATES_SUCCEED,
              payload: {
                createStatus: true,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_CERTIFICATES_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      });
  };

const updateCertificatesForms =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_CERTIFICATES_FORMS,
      payload: {
        certificatesForms: params,
      },
    });
  };

// User profile honours section
const createHonours =
  (params = {}, isEdit) =>
  async (dispatch, getState) => {
    let payload = {};
    if (isEdit) {
      payload = {
        query: `mutation {
          saveHonorAndAwards(
            input: {
              honorAndAwards: [
                {
                  id: "${params.id}"
                  title: "${params.title}"
                  description: """${params.description}""",
                  completionYear: ${params.completion_year} 
                }
              ]
            }
          ) {
            success
            honorAndAwards {
              id
              title
              description
              completionYear 
            }
          }
        }`,
      };
    } else {
      payload = {
        query: `mutation {
          saveHonorAndAwards(
            input: {
              honorAndAwards: [
                {
                  title: "${params.title}"
                  description: """${params.description}""",
                  completionYear: ${params.completion_year} 
                }
              ]
            }
          ) {
            success
            honorAndAwards {
              id
              title
              description
              completionYear 
              createdAt
              updatedAt
            }
          }
        }`,
      };
    }

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveHonorAndAwards?.success) {
            const { honorAndAwards } = response.data?.data?.saveHonorAndAwards;

            // Get response data to payload
            dispatch({
              type: types.UPDATE_HONOURS,
              payload: {
                newHonour: { nodes: honorAndAwards },
              },
            });

            // When success
            dispatch({
              type: types.UPDATE_HONOURS_SUCCEED,
              payload: {
                createStatus: true,
              },
            });
          } else {
            return dispatch({
              type: types.UPDATE_HONOURS_FAILED,
              payload: {
                createStatus: false,
                errorMessage: response?.data?.errors[0]?.message,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_HONOURS_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: types.UPDATE_HONOURS_FAILED,
          payload: {
            createStatus: false,
            errorMessage: error.toString(),
          },
        })
      );
  };

const deleteHonours =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `mutation {
        saveHonorAndAwards(
          input: {
            honorAndAwards: [
              {
                id: "${params.id}"
                _destroy: true
              }
            ]
          }
        ) {
          success
          honorAndAwards {
            id
            title
            description
            completionYear 
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveHonorAndAwards?.success) {
            const { honorAndAwards } = response.data?.data?.saveHonorAndAwards;

            // Get response data to payload
            dispatch({
              type: types.DELETE_HONOURS,
              payload: {
                newHonour: { nodes: honorAndAwards },
              },
            });

            dispatch({
              type: types.UPDATE_HONOURS_SUCCEED,
              payload: {
                createStatus: true,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_HONOURS_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      });
  };

// User profile extracurricular section
const createExtracurricular =
  (params = {}, isEdit) =>
  async (dispatch, getState) => {
    let payload = {};
    if (isEdit) {
      payload = {
        query: `mutation {
          saveExtracurriculars(
            input: {
              extracurriculars: [
                {
                  id: "${params.id}"
                  title: "${params.title}"
                  description: """${params.description}""",
                  completionYear: ${params.completion_year} 
                }
              ]
            }
          ) {
            success
            extracurriculars {
              id
              title
              description
              completionYear 
            }
          }
        }`,
      };
    } else {
      payload = {
        query: `mutation {
          saveExtracurriculars(
            input: {
              extracurriculars: [
                {
                  title: "${params.title}"
                  description: """${params.description}""",
                  completionYear: ${params.completion_year} 
                }
              ]
            }
          ) {
            success
            extracurriculars {
              id
              title
              description
              completionYear 
            }
          }
        }`,
      };
    }

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveExtracurriculars?.success) {
            const { extracurriculars } =
              response.data?.data?.saveExtracurriculars;

            // Get response data to payload
            dispatch({
              type: types.UPDATE_EXTRACURRICULAR,
              payload: {
                newExtracurricular: { nodes: extracurriculars },
              },
            });

            dispatch({
              type: types.UPDATE_EXTRACURRICULAR_SUCCEED,
              payload: {
                createStatus: true,
              },
            });
          } else {
            return dispatch({
              type: types.UPDATE_EXTRACURRICULAR_FAILED,
              payload: {
                createStatus: false,
                errorMessage: response?.data?.errors[0]?.message,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_EXTRACURRICULAR_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      });
  };

const deleteExtracurricular =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `mutation {
        saveExtracurriculars(
          input: {
            extracurriculars: [
              {
                id: "${params.id}"
                _destroy: true
              }
            ]
          }
        ) {
          success
          extracurriculars {
            id
            title
            description
            completionYear 
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveExtracurriculars?.success) {
            const { extracurriculars } =
              response.data?.data?.saveExtracurriculars;

            // Get response data to payload
            dispatch({
              type: types.DELETE_EXTRACURRICULAR,
              payload: {
                newExtracurricular: { nodes: extracurriculars },
              },
            });

            dispatch({
              type: types.UPDATE_EXTRACURRICULAR_SUCCEED,
              payload: {
                createStatus: true,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_EXTRACURRICULAR_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      });
  };

const createEducation =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATING_USER_EDUCATION,
      payload: {
        isUpdating: true,
      },
    });

    const {
      educationLevel: inputEduLevel,
      educationalInstitution: inputEduInstitute,
      fieldOfStudy: eduFieldOfStudy,
      graduationYear: eduGradYear,
      description: eduDesc,
    } = params;

    const payload = {
      query: `mutation {
          saveEducations(
            input: {
              educations: [
                {
                  educationLevel: "${inputEduLevel}"
                  educationalInstitution: "${inputEduInstitute}" 
                  fieldOfStudy: "${eduFieldOfStudy}" 
                  graduationYear: ${eduGradYear}
                  description: """${eduDesc}"""
                }
              ]
            }
          ) {
            success
            educations {
              id
              educationLevel
              educationalInstitution
              fieldOfStudy 
              graduationYear
              description
              createdAt
              updatedAt
            }
          }
        }
        `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        dispatch({
          type: types.UPDATING_USER_EDUCATION,
          payload: {
            isUpdating: false,
          },
        });

        if (response.status === 200) {
          const { success, educations } = response.data?.data?.saveEducations;
          const { errors } = response.data;

          if (success === false && errors?.length > 0) {
            return dispatch({
              type: types.UPDATE_EDUCATION_FAILED,
              payload: {
                errorMessage: errors[0]?.message,
              },
            });
          }

          let newList = {
            nodes: educations,
          };

          dispatch({
            type: types.UPDATE_USER_EDUCATION_LIST,
            payload: {
              newEducationList: newList,
            },
          });

          return dispatch({
            type: types.RESET_EDUCATION_FORM,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.UPDATE_EDUCATION_FAILED,
          payload: {
            errorMessage: error.toString(),
          },
        });
      });
  };

const resetEducationFormState = () => async (dispatch, getState) => {
  dispatch({
    type: types.RESET_EDUCATION_FORM,
  });
};

const updateEducationFormState =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_EDUCATION_FORM_STATE,
      payload: {
        educationForm: params,
      },
    });
  };

// User profile work experience section
const createWork =
  (params = {}, isEdit) =>
  async (dispatch, getState) => {
    let payload = {};
    if (isEdit) {
      payload = {
        query: `mutation {
          saveWorkingExperiences(
            input: {
              workingExperiences: [
                {
                  id: "${params.id}"
                  companyName: "${params.companyName}"
                  jobTitle: "${params.jobTitle}"
                  description: """${params.description}"""
                  specialization: "${params.specialization}"
                  industryId: ${params.industryId}
                  startDateMonth: ${params.startDateMonth}
                  startDateYear: ${params.startDateYear}
                  endDateMonth: ${params.endDateMonth}
                  endDateYear: ${params.endDateYear}
                  currentWork: ${params.currentWork}
                }
              ]
            }
          ) {
            success
            workingExperiences {
              id
              companyName
              jobTitle
              description
              specialization
              industry {
                id
                title
              }
              startDateMonth
              startDateYear
              endDateMonth
              endDateYear
              currentWork
            }
          }
        }`,
      };
    } else {
      payload = {
        query: `mutation {
          saveWorkingExperiences(
            input: {
              workingExperiences: [
                {
                  companyName: "${params.companyName}"
                  jobTitle: "${params.jobTitle}"
                  description: """${params.description}"""
                  specialization: "${params.specialization}"
                  industryId: ${params.industryId}
                  startDateMonth: ${params.startDateMonth}
                  startDateYear: ${params.startDateYear}
                  endDateMonth:${params.endDateMonth}
                  endDateYear: ${params.endDateYear}
                  currentWork: ${params.currentWork}
                }
              ]
            }
          ) {
            success
            workingExperiences {
              id
              companyName
              jobTitle
              description
              specialization
              industry {
                id
                title
              }
              startDateMonth
              startDateYear
              endDateMonth
              endDateYear
              currentWork
            }
          }
        }`,
      };
    }

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveWorkingExperiences?.success) {
            const { workingExperiences } =
              response.data?.data?.saveWorkingExperiences;

            // Get response data to payload
            dispatch({
              type: types.UPDATE_WORK,
              payload: {
                newWork: { nodes: workingExperiences },
              },
            });

            return dispatch({
              type: types.UPDATE_WORK_SUCCEED,
              payload: {
                createStatus: true,
                newWork: workingExperiences,
              },
            });
          } else if (response.data?.errors?.length > 0) {
            return dispatch({
              type: types.UPDATE_WORK_FAILED,
              payload: {
                createStatus: false,
                errorMessage: response.data?.errors[0]?.message,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_WORK_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: types.UPDATE_WORK_FAILED,
          payload: {
            createStatus: false,
            errorMessage: error.toString(),
          },
        })
      );
  };

const deleteWork =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `mutation {
        saveWorkingExperiences(
          input: {
            workingExperiences: [
              {
                id: "${params.id}"
                _destroy: true
              }
            ]
          }
        ) {
          success
          workingExperiences {
            id
            companyName
            jobTitle
            description
            specialization
            industry {
              id
              title
            }
            startDateMonth
            startDateYear
            endDateMonth
            endDateYear
            currentWork
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.saveWorkingExperiences?.success) {
            const { workingExperiences } =
              response.data?.data?.saveWorkingExperiences;

            // Get response data to payload
            dispatch({
              type: types.UPDATE_WORK,
              payload: {
                newWork: { nodes: workingExperiences },
              },
            });

            return dispatch({
              type: types.UPDATE_WORK_SUCCEED,
              payload: {
                createStatus: true,
                newWorkList: workingExperiences,
              },
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_WORK_FAILED,
            payload: {
              createStatus: false,
            },
          });
        }
      });
  };

const updateExistingEducation =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATING_USER_EDUCATION,
      payload: {
        isUpdating: true,
      },
    });

    const {
      educationId,
      educationLevel,
      educationalInstitution,
      fieldOfStudy,
      graduationYear,
      description,
      destroyEducation,
    } = params;

    const educationLevelQuery = educationLevel
      ? `educationLevel: "${educationLevel}"`
      : "";
    const educationalInstitutionQuery = educationalInstitution
      ? `educationalInstitution: "${educationalInstitution}"`
      : "";
    const fieldOfStudyQuery = fieldOfStudy
      ? `fieldOfStudy: "${fieldOfStudy}"`
      : "";
    const graduationYearQuery = graduationYear
      ? `graduationYear: ${graduationYear}`
      : "";
    const descriptionQuery = description
      ? `description: """${description}"""`
      : "";
    const destroyEducationQuery = destroyEducation
      ? `_destroy: ${destroyEducation}`
      : "";

    const queryInput = `{
        id: ${educationId}
        ${educationLevelQuery}
        ${educationalInstitutionQuery}
        ${fieldOfStudyQuery}
        ${graduationYearQuery}
        ${descriptionQuery}
        ${destroyEducationQuery}
      }`;

    const payload = {
      query: `
        mutation {
          saveEducations(
            input: {
              educations: [
                ${queryInput}
              ]
            }
          ) {
            success
            educations {
              id
              educationLevel
              educationalInstitution
              fieldOfStudy 
              graduationYear
              description
              createdAt
              updatedAt
            }
          }
        }
        `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        dispatch({
          type: types.UPDATING_USER_EDUCATION,
          payload: {
            isUpdating: false,
          },
        });

        if (response.status === 200) {
          const { success, educations } = response.data?.data?.saveEducations;
          const { errors } = response.data;

          if (success === false && errors?.length > 0) {
            return dispatch({
              type: types.UPDATE_EDUCATION_FAILED,
              payload: {
                errorMessage: errors[0]?.message,
              },
            });
          }

          let newList = {
            nodes: educations,
          };

          dispatch({
            type: types.UPDATE_USER_EDUCATION_LIST,
            payload: {
              newEducationList: newList,
            },
          });

          return dispatch({
            type: types.RESET_EDUCATION_FORM,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.UPDATE_EDUCATION_FAILED,
          payload: {
            errorMessage: error.toString(),
          },
        });
      });
  };

const saveUserLanguages =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.SAVING_USER_LANGUAGES,
    });

    const { latestLanguages } = params;

    const mappedLanguages = latestLanguages.map(
      (node) =>
        `{ language: "${node.language}", proficiencyLevel: "${node.proficiency_level}"}`
    );

    const payload = {
      query: `
        mutation {
          saveLanguages(
            input: {languages: [${mappedLanguages}]}
          ) {
            success
            languages
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { success, languages: newLanguages } =
            response.data?.data?.saveLanguages;

          const { errors } = response.data;

          if (success === false && errors?.length > 0) {
            return dispatch({
              type: types.SAVING_USER_LANGUAGES_FAIL,
              errorMessage: errors[0]?.message,
            });
          }

          dispatch({
            type: types.SAVE_USER_UPDATED_LANGUAGES,
            payload: {
              stillSaving: !success,
              languages: newLanguages,
            },
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: types.SAVING_USER_LANGUAGES_FAIL,
          errorMessage: error.toString(),
        })
      );
  };

const mutateProjectAndPortfolio =
  (params = {}) =>
  async (dispatch, getState) => {
    // dispatch here
    dispatch({
      type: types.SAVING_USER_PROJECTS,
    });

    const {
      projectId: ppId,
      projectTitle: ppTitle,
      projectYear: ppCompleteYear,
      projectUrl: ppUrl,
      description: ppDesc,
      destroyProject: _ppDestroy,
    } = params;

    // If there is `ppId`, it implies it is an edit operation
    const payload = ppId
      ? {
          query: `
            mutation {
              saveProjectAndPortfolios(
                input: {
                  projectAndPortfolios: [
                    {
                      id: ${ppId}
                      title: "${ppTitle}"
                      description: """${ppDesc}"""
                      completionYear: ${ppCompleteYear}
                      projectUrl: "${ppUrl}"
                      _destroy: ${_ppDestroy}
                    }
                  ]
                }
              ) {
                success
                projectAndPortfolios {
                  id
                  title
                  description
                  projectUrl
                  completionYear 
                  createdAt
                  updatedAt
                }
              }
            }          
          `,
        }
      : {
          query: `
            mutation {
              saveProjectAndPortfolios(
                input: {
                  projectAndPortfolios: [
                    {
                      title: "${ppTitle}"
                      description: """${ppDesc}"""  
                      completionYear: ${ppCompleteYear}
                      projectUrl: "${ppUrl}"
                    }
                  ]
                }
              ) {
                success
                projectAndPortfolios {
                  id
                  title
                  description
                  projectUrl
                  completionYear 
                  createdAt
                  updatedAt
                }
              }
            }
          `,
        };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { success, projectAndPortfolios } =
            response.data?.data?.saveProjectAndPortfolios;

          const { errors } = response.data || {};

          if (success === false && errors?.length > 0) {
            return dispatch({
              type: types.UPDATE_PROJECTS_FAILED,
              payload: {
                errorMessage: errors[0]?.message,
              },
            });
          }
          // Append the project & portfolio list into the following structure
          // Abide the original response structure from "me" or "login" v1 api
          let newList = {
            nodes: projectAndPortfolios,
          };

          dispatch({
            type: types.RESET_PROJECT_FORM_STATE,
          });

          return dispatch({
            type: types.SAVE_USER_UPDATED_PROJECTS,
            payload: {
              projects: newList,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_PROJECTS_FAILED,
          errorMessage: error.toString(),
        });
      });
  };

const updateProjectFormState =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_PROJECT_FORM_STATE,
      payload: {
        projectForm: params,
      },
    });
  };

const resetProjectFormState = () => async (dispatch, getState) => {
  dispatch({
    type: types.RESET_PROJECT_FORM_STATE,
  });
};

const updateUserProfile =
  (params = {}, isEdit) =>
  async (dispatch, getState) => {
    const nameQuery = params.name ? `name: "${params.name}"` : "";
    const firstNameQuery = params.firstName
      ? `firstName: "${params.firstName}"`
      : "";
    const emailQuery = params.email ? `email: "${params.email}"` : "";
    const mobileNumberQuery = params.mobileNumber
      ? `mobileNumber: "${params.mobileNumber}"`
      : "";
    const genderQuery = params.gender ? `gender: "${params.gender}"` : "";
    const shortSummaryQuery = params.shortSummary
      ? `shortSummary: "${params.shortSummary}"`
      : "";
    const nationalityQuery = params.nationality
      ? `nationality: "${params.nationality}"`
      : "";
    const currentLocationQuery = params.currentLocation
      ? `currentLocation: "${params.currentLocation}"`
      : "";
    const birthDateQuery = params.birthDate
      ? `birthDate: "${params.birthDate}"`
      : "";
    const videoCvQuery = params.videoCv ? `videoCv: "${params.videoCv}"` : "";
    const videoCvDeviceTypeQuery = params.videoCvDeviceType
      ? `videoCvDeviceType: "${params.videoCvDeviceType}"`
      : "";
    const cvDropQuery = params.cvDrop ? `cvDrop: "${params.cvDrop}"` : "";
    const freshGradQuery =
      params.freshGrad !== undefined ? `freshGrad: ${params.freshGrad}` : "";
    const websiteUrlTitleQuery = params.websiteUrlTitle
      ? `websiteUrlTitle: "${params.websiteUrlTitle}"`
      : "";
    const websiteUrlQuery = params.websiteUrl
      ? `websiteUrl: "${params.websiteUrl}"`
      : "";

    const queryInput = `${nameQuery} ${firstNameQuery} ${emailQuery} ${mobileNumberQuery} ${genderQuery} ${shortSummaryQuery} ${nationalityQuery} ${currentLocationQuery} ${birthDateQuery} ${videoCvQuery} ${videoCvDeviceTypeQuery} ${cvDropQuery} ${freshGradQuery} ${websiteUrlTitleQuery} ${websiteUrlQuery}`;

    const payload = {
      query: `mutation {
          saveProfile(input: {
            ${queryInput}
          }) {
            user {
              ${newUserAttributes}
            }
          }
        }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.saveProfile;
          const { errors } = response.data;

          if (errors && Array.isArray(errors) && errors[0]?.message) {
            return dispatch({
              type: types.UPDATE_USER_BASIC_INFO_FAILED,
              message: errors[0]?.message ?? "Error while saving user profile.",
            });
          }

          user && setItem(types.USER_PROFILE, JSON.stringify(user));

          // posthog.capture("user_profile_update", {
          //   age: parseInt(user?.age ?? 0),
          //   gender: user?.gender,
          //   nationality: user?.nationality,
          //   is_actively_available: user?.activelyAvailable,
          //   has_resume: user?.resume ? true : false,
          //   current_location: user?.currentLocation,
          //   is_fresh_grad: user?.freshGrad,
          //   is_onboarding_completed: user?.onboardingComplete,
          //   signup_platform_device_os: user?.signupPlatformDeviceOs,
          //   track_ids: user?.trackIds,
          //   name: user?.name,
          //   email: user?.email,
          // });

          dispatch({
            type: types.UPDATE_USER_PROFILE_SUCCEED,
            user: user,
          });

          // Get response data to payload
          return dispatch({
            type: types.UPDATE_USER_BASIC_INFO,
            payload: {
              newInfo: user,
            },
          });
        } else {
          return dispatch({
            type: types.UPDATE_USER_BASIC_INFO_FAILED,
            message: "Error while saving user profile.",
          });
        }
      });
  };

const updateUserMobileNumberAndNationality =
  (params = {}, isEdit) =>
  async (dispatch, getState) => {
    const payload = {
      query: `mutation {
          saveProfile(input: {
            name: "${params.name}"
            email: "${params.email}"
            mobileNumber: "${params.mobileNumber}"
            nationality: "${params.nationality}"
            currentLocation: "${params.currentLocation}"
            freshGrad: ${params.freshGrad}
          }) {
            user {
              ${newUserAttributes}
            }
          }
        }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.saveProfile;
          const { errors } = response.data;

          if (errors && Array.isArray(errors) && errors[0]?.message) {
            return dispatch({
              type: types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_FAILED,
              message:
                errors[0]?.message ??
                "Failed to update mobile number, current location, & nationality.",
            });
          }

          // Get response data to payload
          return dispatch({
            type: types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_SUCCESS,
            payload: user,
          });
        } else {
          return dispatch({
            type: types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_FAILED,
            message:
              "Failed to update mobile number, current location, & nationality.",
          });
        }
      });
  };

const removeProfileImage =
  (params = {}, isEdit) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.REMOVE_PROFILE_PHOTO_IN_PROGRESS,
    });

    const payload = {
      query: `
        mutation {
          saveProfile(input: {deleteProfileImage: true}) {
            user {
              ${newUserAttributes}
            }
          }
        }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { user } = response.data?.data?.saveProfile;
          const { errors } = response.data;

          if (!user || (Array.isArray(errors) && errors[0]?.message)) {
            return dispatch({
              type: types.REMOVE_PROFILE_PHOTO_FAILED,
              errorMessage:
                errors[0]?.message ?? "Error while updating profile photo",
            });
          }

          return dispatch({
            type: types.REMOVE_PROFILE_PHOTO_SUCCESS,
            user: user,
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: types.REMOVE_PROFILE_PHOTO_FAILED,
          errorMessage:
            error?.toString() ?? "Error while updating profile photo",
        })
      );
  };

const validateHiredlyResumeContent =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.VALIDATE_HIREDLY_RESUME });

    // NOTE: Replace to use full ashley url
    return api
      .apiCall(process.env.NEXT_ASHLEY_API_URL, params, {})
      .then(async (response) => {
        if (response.status === 200 && response.data?.result) {
          return dispatch({
            type: types.VALIDATE_HIREDLY_RESUME_SUCCEED,
            result: response?.data?.result,
          });
        }

        return dispatch({
          type: types.VALIDATE_HIREDLY_RESUME_FAILED,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.VALIDATE_HIREDLY_RESUME_FAILED,
        });
      });
  };

const updateBasicInfo =
  (params = {}) =>
  async (dispatch, getState) => {
    const formFields = params;
    return dispatch({
      type: types.UPDATE_USER_BASIC_INFO,
      payload: {
        newInfo: formFields,
      },
    });
  };

const updateHiredlyResumeBuildingStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_NEW_RESUME_BUILDING_STATUS,
    status: status,
  });
};

const updateReplaceResumePopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_REPLACE_RESUME_POPUP_STATUS,
    status: status,
  });
};

const updateResumeProgressPopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_RESUME_PROGRESS_POPUP_STATUS,
    status: status,
  });
};

const updateResumeFailPopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_RESUME_FAIL_POPUP_STATUS,
    status: status,
  });
};

const updateLanguageCheckPopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_LANGUAGE_CHECK_POPUP_STATUS,
    status: status,
  });
};

const updateResumeBuildingStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_RESUME_BUILDING_STATUS,
    status: status,
  });
};

const updateResumeUploadingStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_RESUME_UPLOADING_STATUS,
    status: status,
  });
};

const updateResumeDoneStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_RESUME_DONE_STATUS,
    status: status,
  });
};

const updateCreateResumePopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_CREATE_RESUME_POPUP_STATUS,
    status: status,
  });
};

const updateRejectedResumePopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_REJECTED_RESUME_POPUP_STATUS,
    status: status,
  });
};

const updateIsHiredlyResumeRejectedStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_IS_HIREDLY_RESUME_REJECTED_STATUS,
    status: status,
  });
};

const updatePreviewResumePoupupStatus = (props) => async (dispatch) => {
  const { status, from } = props;
  return dispatch({
    type: types.UPDATE_PREVIEW_RESUME_POPUP_STATUS,
    status: status,
    from: from,
  });
};

const updateFailedUploadResumePopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_FAILED_UPLOAD_RESUME_POPUP_STATUS,
    status: status,
  });
};

const updateLinkedinResumePopupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_LINKEDIN_RESUME_POPUP_STATUS,
    status: status,
  });
};

const getUserResumeBlob =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{ 
        downloadResume
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { resume, filename } = response?.data?.data?.downloadResume;

          if (resume) {
            return dispatch({
              type: types.GET_RESUME_SUCCESS,
              resumeBlob: resume,
              resumeFilename: filename,
            });
          } else {
            return dispatch({
              type: types.GET_RESUME_FAILED,
            });
          }
        } else {
          return dispatch({
            type: types.GET_RESUME_FAILED,
          });
        }
      })
      .catch((error) => {
        console.error("Error", error);
        dispatch({
          type: types.GET_RESUME_FAILED,
        });
      });
  };

const updateCurrentTooltip =
  (params = {}) =>
  async (dispatch) => {
    return dispatch({
      type: types.SET_CURRENT_TOOLTIP,
      payload: {
        currentTooltip: params.currentTooltip,
        tooltipOpen: params.tooltipOpen,
      },
    });
  };
const resetCurrentTooltip = () => async (dispatch) => {
  return dispatch({
    type: types.RESET_CURRENT_TOOLTIP,
  });
};

// Lightcast for onboarding
const fetchingJobList = (queryString) => async (dispatch, getState) => {
  const lightCastToken = localStorage.getItem("lightcast-token");
  const client = axios.create({
    baseURL: "https://emsiservices.com",
    headers: { Authorization: `Bearer ${lightCastToken}` },
  });
  const path = "/titles/versions/latest/titles";

  const requestParams = new URLSearchParams();
  requestParams.append("q", queryString);
  requestParams.append("limit", 10);
  requestParams.append("page", 1);

  client
    .get(path, { params: requestParams })
    .then((response) => {
      if (response.data.data !== null || response.data.data !== undefined) {
        // If no result from lightcast, append user input as option
        if (response.data.data.length < 1) {
          return dispatch({
            type: types.UPDATE_LIGHTCAST_JOBS_LIST,
            payload: {
              obtainedJobs: [queryString],
            },
          });
        } else {
          let obtainedJobs = response.data.data.map((job) => job.name);
          return dispatch({
            type: types.UPDATE_LIGHTCAST_JOBS_LIST,
            payload: {
              obtainedJobs: obtainedJobs,
            },
          });
        }
      }
    })
    .catch((error) => console.error(error));
};

const updateCurrentOnboardingPage = (page) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_CURRENT_ONBOARDING_PAGE,
    page: page,
  });
};

const updateJobTitleSuggestionsDropdownStatus =
  (status) => async (dispatch) => {
    return dispatch({
      type: types.UPDATE_JOB_TITLE_DROPDOWN_STATUS,
      status: status,
    });
  };

const updateIndustrySuggestionsDropdownStatus =
  (status) => async (dispatch) => {
    return dispatch({
      type: types.UPDATE_INDUSTRY_DROPDOWN_STATUS,
      status: status,
    });
  };

const updateSpecialisationSuggestionsDropdownStatus =
  (status) => async (dispatch) => {
    return dispatch({
      type: types.UPDATE_SPECIALISATION_DROPDOWN_STATUS,
      status: status,
    });
  };

const updateMajorSuggestionsDropdownStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_MAJOR_DROPDOWN_STATUS,
    status: status,
  });
};

const updateSkillSuggestionsDropdownStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SKILL_DROPDOWN_STATUS,
    status: status,
  });
};

const updateProgressbarValue = (value) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_PROGRESS_BAR_VALUE,
    value: value,
  });
};

const updateSelectedRecommendedJobs = (ids) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SELECTED_RECOMMENDED_JOBS,
    ids: ids,
  });
};

const updateBulkJobsApplicationPoupupStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_BULK_JOBS_POPUP_STATUS,
    status: status,
  });
};

const resetOnboardingFields = () => async (dispatch) => {
  return dispatch({
    type: types.RESET_ONBOARDING_FIELDS,
  });
};

const resetOnboardingStatus = () => async (dispatch) => {
  return dispatch({
    type: types.RESET_ONBOARDING_STATUS,
  });
};

const updateSliderDotsHistory = (dots) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SLIDER_DOTS_HISTORY,
    history: dots,
  });
};

const updateNationalityDialog = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_DIALOG,
    payload: {
      showNationalityDialog: params.showNationalityDialog,
    },
  });
};

const updateJobCountryPreferenceDialog = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_COUNTRY_PREFERENCE_DIALOG,
    payload: {
      showJobCountryPreferenceDialog: params,
    },
  });
};

const updateJobCountryPreference = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_COUNTRY_PREFERENCE,
    payload: {
      JobCountryPreference: params,
    },
  });
};

const openSpecialisationDropdown = (param) => async (dispatch) => {
  return dispatch({
    type: types.OPEN_SPECIALISATION_DROPDOWN,
    payload: {
      openSpecialisationDropdown: param,
    },
  });
};

const openSubSpecialisationDropdown = (param) => async (dispatch) => {
  return dispatch({
    type: types.OPEN_SUB_SPECIALISATION_DROPDOWN,
    payload: {
      openSubSpecialisationDropdown: param,
    },
  });
};

const updateSubSpecialisation = (param) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SUB_SPECIALISATION,
    payload: {
      subSpecialisation: param,
    },
  });
};

const uploadResume = (param) => async (dispatch, getState) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPLOAD_RESUME_SUCCESS,
        payload: param,
      })
    );
  });
};

const checkEmailExistance =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `mutation {
      checkEmail(input: {
        email: "${params.email}"
      }) {
        emailExists
        errors
      }
    }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { emailExists } = response?.data?.data?.checkEmail;
          return emailExists;
        }
      })
      .catch((error) => {
        console.error("error:", error);
      });
  };

const fetchParsedResumeSections =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
          parsedResumeSections {
            educationsStructured
            workExperiencesStructured
            certifications
            languages
            skills
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then((res) => {
        if (res?.data?.data) {
          let { parsedResumeSections } = res.data.data || {};
          if (!parsedResumeSections) {
            //error
            return dispatch({
              type: types.FETCH_PARSED_RESUME_SECTIONS_FAILED,
            });
          }

          // Empty resume
          if (
            !parsedResumeSections?.workExperiencesStructured?.length &&
            !parsedResumeSections?.educationsStructured?.length &&
            !parsedResumeSections?.languages?.length &&
            !parsedResumeSections?.certifications?.length &&
            !parsedResumeSections?.skills?.length
          ) {
            return dispatch({
              type: types.FETCH_EMPTY_PARSED_RESUME_SECTIONS,
            });
          }

          // // Revamp workExperience data structure
          // // 1. Convert date: "2022-09-17" to dateYear: "2002", dateMonth: "Sep"
          // // 2. Convert special character "" to bullet point "•"

          if (parsedResumeSections?.workExperiencesStructured?.length) {
            parsedResumeSections.workExperiencesStructured =
              parsedResumeSections.workExperiencesStructured?.map(
                (workExperience, index) => {
                  let formattedWorkExperience = workExperience;
                  if (workExperience?.startDate?.year) {
                    formattedWorkExperience.startDateYear =
                      formattedWorkExperience.startDate.year.toString();
                  }
                  if (workExperience?.startDate?.month) {
                    workExperience.startDateMonth = months.filter(
                      ({ label, id }) => id === workExperience.startDate.month
                    )[0].label;
                  }
                  if (workExperience?.endDate?.year) {
                    formattedWorkExperience.endDateYear =
                      formattedWorkExperience.endDate.year.toString();
                  }
                  if (workExperience?.endDate?.month) {
                    workExperience.endDateMonth = months.filter(
                      ({ label, id }) => id === workExperience.endDate.month
                    )[0].label;
                  }

                  // 2. Convert special character "" to bullet point "•"
                  if (workExperience?.jobDescription) {
                    if (workExperience?.jobDescription.includes("")) {
                      const specialCharacterPattern = //g;
                      const replaceSymbol = "•";
                      formattedWorkExperience = {
                        ...formattedWorkExperience,
                        jobDescription: workExperience?.jobDescription.replace(
                          specialCharacterPattern,
                          replaceSymbol
                        ),
                      };
                    }
                  }
                  return formattedWorkExperience;
                }
              );
          }

          // Add selected key to Work, Education, Language, Cert
          for (const key in parsedResumeSections) {
            if (Array.isArray(parsedResumeSections[key])) {
              parsedResumeSections[key] = parsedResumeSections[key]?.map(
                (resumeSection) => {
                  if (typeof resumeSection === "object") {
                    return { ...resumeSection, selected: false };
                  }
                  return resumeSection;
                }
              );
            }
          }

          return dispatch({
            type: types.FETCH_PARSED_RESUME_SECTIONS_SUCCESS,
            parsedResumeSections: parsedResumeSections,
          });
        } else {
          return dispatch({
            type: types.FETCH_PARSED_RESUME_SECTIONS_FAILED,
          });
        }
      });
  };

const updateOnboardingResume =
  (params = {}) =>
  async (dispatch, getState) => {
    try {
      const formattedWorkExperiences = escapeDoubleQuoteFromPayload(
        params?.workExperiences
      );

      let mappedWorkExperiences;
      if (formattedWorkExperiences?.length) {
        mappedWorkExperiences = formattedWorkExperiences?.map(
          (workExperience) => {
            let startDateMonthId;
            let endDateMonthId;
            // Convert month to Int id, e.g., Sep -> 9
            if (typeof workExperience?.startDateMonth === "string") {
              startDateMonthId = months.find(
                ({ label, id }) => label === workExperience.startDateMonth
              ).id;
            } else {
              startDateMonthId = workExperience?.startDateMonth?.id;
            }

            if (typeof workExperience?.endDateMonth === "string") {
              endDateMonthId = months.find(
                ({ label, id }) => label === workExperience.endDateMonth
              ).id;
            } else {
              endDateMonthId = workExperience?.endDateMonth?.id;
            }

            return `{
          companyName: "${workExperience?.companyName}",
          jobTitle: "${workExperience?.jobTitle}",
          description: ${
            workExperience?.jobDescription
              ? `"""${workExperience?.jobDescription}"""`
              : null
          },
          specialization: ${
            workExperience?.specialisation
              ? `"${workExperience.specialisation}"`
              : null
          },
          industryId: ${workExperience?.industry?.id ?? null},
          startDateMonth: ${startDateMonthId ?? null},
          startDateYear: ${workExperience?.startDateYear ?? null},
          endDateMonth: ${endDateMonthId ?? null},
          endDateYear: ${workExperience?.endDateYear ?? null},
          currentWork: ${!!workExperience?.currentWork},
        }`;
          }
        );
      }

      const formattedEducations = escapeDoubleQuoteFromPayload(
        params?.educations
      );
      let mappedEducations;
      if (formattedEducations?.length) {
        mappedEducations = formattedEducations?.map((education) => {
          return `{
          educationLevel: "${education?.educationLevel}",
          educationalInstitution: "${education?.institution}",
          fieldOfStudy: "${education?.educationName}",
          graduationYear: ${education?.graduationYear},
          description: """${education?.description}""",
        }`;
        });
      }

      const formattedSkills = escapeDoubleQuoteFromPayload(params?.skills);
      let mappedSkills;
      if (formattedSkills?.length) {
        mappedSkills = formattedSkills?.map((skill) => {
          return `{ name: "${skill}" }`;
        });
      }

      const formattedLanguages = escapeDoubleQuoteFromPayload(
        params?.languages
      );
      let mappedLanguages;
      if (formattedLanguages?.length) {
        mappedLanguages = formattedLanguages?.map((language) => {
          return `{
          language: "${language?.language}",
          proficiencyLevel: ${
            language?.proficiency ? `"${language?.proficiency}"` : null
          },
        }`;
        });
      }

      const formattedCertificates = escapeDoubleQuoteFromPayload(
        params?.certificates
      );
      let mappedCertificates;
      if (formattedCertificates?.length) {
        mappedCertificates = formattedCertificates?.map((certificate) => {
          return `{
          title: ${certificate?.name ? `"${certificate.name}"` : null},
          issuingOrganization: ${
            certificate?.organisation ? `"${certificate.organisation}"` : null
          },
          completionYear: ${certificate?.year},
          credentialUrl: ${certificate?.url ? `"${certificate?.url}"` : null},
        }`;
        });
      }

      const payload = {
        query: `mutation {
        saveResume(
          input: {
            resumeInfo: {
              workingExperiences: ${
                mappedWorkExperiences ? `[${mappedWorkExperiences}]` : null
              },
              educations: ${mappedEducations ? `[${mappedEducations}]` : null},
              skills: ${mappedSkills ? `[${mappedSkills}]` : null},
              languages: ${mappedLanguages ? `[${mappedLanguages}]` : null},
              certificateAndTrainings: ${
                mappedCertificates ? `[${mappedCertificates}]` : null
              }
            }
          }
        ) {
          success
        }
      }`,
      };

      return api
        .apiCall("/api/job_seeker/v1/graphql", {}, payload)
        .then((res) => {
          if (res.status === 200) {
            if (res?.data?.data?.saveResume?.success) {
              return dispatch({
                type: types.UPDATE_ONBOARDING_RESUME_SUCCESS,
              });
            }
          }
          return dispatch({
            type: types.UPDATE_ONBOARDING_RESUME_FAILED,
          });
        });
    } catch (error) {
      return dispatch({ type: types.UPDATE_ONBOARDING_RESUME_FAILED });
    }
  };

const getAshleyShortSummary =
  (params = {}) =>
  async (dispatch, getState) => {
    const currentState = getState();
    const userBasicInfo = currentState?.user?.basicInfo;

    dispatch(updateAshleyEditDialog(true));
    dispatch({
      type: types.FETCHING_ASHLEY_SHORT_SUMMARY,
    });

    const experienceLevel = userBasicInfo?.fresh_grad
      ? "fresh_grad"
      : "experienced";
    const payload = {
      query: `{
          jobSeekerShortSummary(experienceLevel: "${experienceLevel}") {
            temperature
            diversityPenalty
            output
          }
        }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then((response) => {
        if (response.status === 200) {
          const { jobSeekerShortSummary } = response?.data?.data || {};

          if (jobSeekerShortSummary && jobSeekerShortSummary.length) {
            return dispatch({
              type: types.FETCH_ASHLEY_SHORT_SUMMARY_SUCCEED,
              payload: jobSeekerShortSummary,
            });
          }
        }
        return dispatch({
          type: types.FETCH_ASHLEY_SHORT_SUMMARY_FAILED,
          errorMessage: "Something went wrong, please try again later",
        });
      });
  };

const updateSelectedShortSummary =
  (shortSummary) => async (dispatch, getState) => {
    return dispatch({
      type: types.UPDATE_SELECTED_SHORT_SUMMARY,
      payload: shortSummary,
    });
  };

const hydrateUserObject = (localStorageUser) => async (dispatch) => {
  dispatch({
    type: types.FETCH_PERSONA_SUCCESS,
    payload: localStorageUser?.workPersona ?? {},
  });

  return dispatch({
    type: types.HYDRATE_REDUX_USER,
    user: localStorageUser,
  });
};

// -------------------- FOR UNIVERSAL LOGIN USE --------------------
// globalId - logged in user's global ID
const triggerSwitchCountry =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `
      mutation {
        switchCountry(input: {globalId: "${params.globalId}"}) { 
          success
          token
        }
      }    
      `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status === 200) {
      const { error, data } = response?.data;

      if (Array.isArray(error) && error.length > 0) {
        return {
          success: false,
          token: null,
        };
      } else {
        Cookies.set(
          process.env.NEXT_PUBLIC_JWT_COOKIE,
          data?.switchCountry?.token,
          {
            // make sure this is dot hiredly dot com because we need all subdomain to access this value.
            domain: ".hiredly.com",
            path: "/",
            expires: 7,
          }
        );

        setItem(types.USER_TOKEN, data?.switchCountry?.token);

        return data?.switchCountry;
      }
    } else
      return {
        success: false,
        token: null,
      };
  };

const switchCountryBearer =
  (params = {}) =>
  async (dispatch) => {
    const user = getUser();
    const countryExist = user.activeCountries.includes(
      params.country.toUpperCase()
    );
    if (countryExist) {
      return;
    }

    const apiUrl = setCountryApi(params);

    const payload = {
      query: `
      mutation {
        switchCountry(input: {globalId: "${params.globalId}"}) { 
          success
          token
        }
      }    
      `,
    };

    const response = await api.apiCall(apiUrl, params, payload);
    if (response.status === 200) {
      const { error, data } = response?.data.data;
      const success = data?.switchCountry?.success;
      if (success) {
        return;
      } else {
        console.error("Error", error);
      }
    }
  };

const checkPDPAStatus =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
      me {
        pdpaAccepted
      }
    }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response?.data?.data?.me != null) {
            const pdpaAccepted = response?.data?.data?.me?.pdpaAccepted;

            return dispatch({
              type: types.FETCH_PDPA_STATUS_SUCCESS,
              payload: pdpaAccepted,
            });
          }
        }
      });
  };

const updatePDPAStatus =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `mutation {
        saveProfile(input: {pdpaAccepted: true}) {
          user{
            pdpaAccepted
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then((response) => {
        if (response.status === 200) {
          const { pdpaAccepted } = response?.data?.data?.saveProfile.user;
          if (pdpaAccepted) {
            return dispatch({
              type: types.FETCH_PDPA_STATUS_SUCCESS,
              payload: pdpaAccepted,
            });
          } else {
            return dispatch({
              type: types.UPDATE_PDPA_STATUS_FAILED,
            });
          }
        } else {
          return dispatch({
            type: types.UPDATE_PDPA_STATUS_FAILED,
          });
        }
      });
  };

const checkUserSession = (params = {}) => {
  const payload = {
    query: `{
      me {
        resetPasswordAt
      }
    }`,
  };
  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload, true)
    .then(async (response) => {
      if (response.status === 200) {
        const { me } = response.data?.data || {};
        if (me != null) {
          return me;
        }
      }
    });
};

const getUserWorkPersona =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `
      {
        me {
          workPersona
        }
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then((response) => {
        if (response.status === 200) {
          const { workPersona } = response?.data?.data?.me;
          if (workPersona) {
            return dispatch({
              type: types.FETCH_PERSONA_SUCCESS,
              payload: workPersona,
            });
          } else {
            return dispatch({
              type: types.FETCH_PERSONA_FAILED,
            });
          }
        } else {
          return dispatch({
            type: types.FETCH_PERSONA_FAILED,
          });
        }
      });
  };

const checkResumeParsedStatus =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
              parsedResumeSections {
                educationsStructured
                workExperiencesStructured
                certifications
                languages
                skills
              }
            }
          `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { parsedResumeSections } = response?.data?.data || {};

          if (
            parsedResumeSections?.workExperiencesStructured.length > 0 ||
            parsedResumeSections?.educationsStructured.length > 0 ||
            parsedResumeSections?.languages.length > 0 ||
            parsedResumeSections?.certifications.length > 0 ||
            parsedResumeSections?.skills.length > 0
          ) {
            return dispatch({
              type: types.CHECK_RESUME_PARSED_STATUS_SUCCEED,
            });
          } else {
            return dispatch({
              type: types.CHECK_RESUME_PARSED_STATUS_FAILED,
            });
          }
        }
        return dispatch({
          type: types.CHECK_RESUME_PARSED_STATUS_FAILED,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.CHECK_RESUME_PARSED_STATUS_FAILED,
        });
      });
  };

const getAllStateRegions =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
        countryStateRegions 
      }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { countryStateRegions } = response?.data?.data;
          return dispatch({
            type: types.GET_ALL_STATE_REGIONS,
            payload: countryStateRegions,
          });
        }
      });
  };

export {
  addNewMessage,
  addSkill,
  changeUserPassword,
  checkAuthentication,
  checkEmailExistance,
  checkPDPAStatus,
  checkResumeParsedStatus,
  checkUserSession,
  clearProfileFormFields,
  countUnreadMessage,
  createCertificates,
  createEducation,
  createExtracurricular,
  createHonours,
  createWork,
  deleteCertificates,
  deleteExtracurricular,
  deleteHonours,
  deleteWork,
  fetchChatList,
  fetchChatMessages,
  fetchingJobList,
  fetchingSkillList,
  fetchParsedResumeSections,
  fetchPresignUrl,
  forgotPassword,
  getAllStateRegions,
  getAshleyShortSummary,
  getCountryPreferences,
  getUser,
  getUserResumeBlob,
  getUserWorkPersona,
  globalLoginFunc,
  hydrateUserObject,
  login,
  logout,
  mutateProjectAndPortfolio,
  newGetUserInfo,
  newResumeUpload,
  openSpecialisationDropdown,
  openSubSpecialisationDropdown,
  profileImageUpload,
  register,
  removeProfileImage,
  removeSkill,
  replaceChat,
  resetCurrentTooltip,
  resetEducationFormState,
  resetLightcastSkills,
  resetOnboardingFields,
  resetOnboardingStatus,
  resetPassword,
  resetProjectFormState,
  saveUserLanguages,
  saveUserSkills,
  sendMessage,
  socialMediaLogin,
  submitContactUs,
  switchCountryBearer,
  triggerSwitchCountry,
  updateBasicInfo,
  updateBulkJobsApplicationPoupupStatus,
  updateCertificatesForms,
  updateCountryPreference,
  updateCreateResumePopupStatus,
  updateCurrentChat,
  updateCurrentOnboardingPage,
  updateCurrentTooltip,
  updateCurrentUser,
  updateEducationFormState,
  updateExistingEducation,
  updateExpectedSalary,
  updateFailedUploadResumePopupStatus,
  updateHiredlyResumeBuildingStatus,
  updateIndustrySuggestionsDropdownStatus,
  updateIsHiredlyResumeRejectedStatus,
  updateJobCountryPreference,
  updateJobCountryPreferenceDialog,
  updateJobExperienceLevel,
  updateJobIndustry,
  updateJobPrefSkills,
  updateJobTitleSuggestionsDropdownStatus,
  updateJobType,
  updateLanguageCheckPopupStatus,
  updateLinkedinResumePopupStatus,
  updateMajorSuggestionsDropdownStatus,
  updateNationalityDialog,
  updateOnboardingDialogStatus,
  updateOnboardingFormFields,
  updateOnboardingInfo,
  updateOnboardingResume,
  updateOnboardingStatus,
  updatePDPAStatus,
  updatePreviewResumePoupupStatus,
  updateProfileFormFields,
  updateProgressbarValue,
  updateProjectFormState,
  updateRejectedResumePopupStatus,
  updateReplaceResumePopupStatus,
  updateResumeBuildingStatus,
  updateResumeDoneStatus,
  updateResumeDrop,
  updateResumeFailPopupStatus,
  updateResumeProgressPopupStatus,
  updateResumeUploadingStatus,
  updateSelectedRecommendedJobs,
  updateSelectedShortSummary,
  updateSkillSuggestionsDropdownStatus,
  updateSliderDotsHistory,
  updateSpecialisationSuggestionsDropdownStatus,
  updateStateRegion,
  updateSubSpecialisation,
  updateTalentAvailable,
  updateTalentSearch,
  updateTrack,
  updateUser,
  updateUserMobileNumberAndNationality,
  updateUserProfile,
  updateUserProfileDrawerIndex,
  updateVCLPublicUrl,
  uploadResume,
  validateFullName,
  validateHiredlyResumeContent,
};
