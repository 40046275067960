import { useRouter } from "next/router";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertBase64 } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import { getIndustries } from "../../../redux/actions/company_action";
import { updateProfilePreviewDialog } from "../../../redux/actions/dialog_action";
import { getUser, newResumeUpload } from "../../../redux/actions/user_action";
import { EditCard } from "./EditCard";
import PreviewCard from "./PreviewCard";
import useResumeSections from "./hooks";
import {
  AboutTextField,
  BetaLabel,
  BlackButton,
  CharacterCount,
  CheckIconStyled,
  CloseButton,
  CloseRoundedIconStyled,
  CompleButton,
  ContentText,
  ContentTitle,
  DialogActionsStyled,
  DialogContentContainer,
  DialogContentWrapper,
  DialogStyled,
  DialogTitleContainer,
  DialogTitleStyled,
  EditAboutContentContainer,
  ErrorIcon,
  FileInput,
  InputContainer,
  InputErrorText,
  InputStyled,
  LinearProgressStyled,
  LoadingButtonStyled,
  LoadingContentWrapper,
  RegularText,
  SectionCardWrapper,
  SectionTitle,
  SelectAllButtonContainer,
  SelectAllTitle,
  SelectButton,
  SelectContainer,
  SkillCardContainer,
  SkillToggleButton,
  ToggleButtonGroupStyled,
  WhiteButton,
} from "./styles";
import {
  Certification,
  CustomInputProps,
  Education,
  Language,
  MultilineTextareaProps,
  ResumeCardProps,
  SkillCardProps,
  WorkExperience,
} from "./types";

export const WORK_EXPERIENCE_DESCRIPTION_MAX_LENGTH = 2000;
export const EDUCATION_DESCRIPTION_MAX_LENGTH = 1000;

export default function ProfilePreviewDialog(props: any) {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const open = useSelector(
    (state: any) => state?.dialog?.showProfilePreviewDialog
  );
  const user = getUser();
  const resumeSections = useResumeSections();
  const [showSelectDialog, setShowSelectDialog] = useState(false);
  const fileInputRef = useRef(null);
  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const [uploadingFile, setUploadingFile] = useState(false);

  function handleCloseDialog() {
    dispatch(updateProfilePreviewDialog(false)).then(() => router.reload());
  }

  function handleAddToProfile() {
    if (resumeSections.hasResumeSectionSelected()) {
      resumeSections.onSaveData();
    } else {
      // posthog.capture("onboarding_user_profile_complete", {
      //   origin: "resume_onboarding",
      //   work_experience: false,
      //   skills: false,
      //   language: false,
      //   education: false,
      //   certificate: false,
      // });
      setShowSelectDialog(true);
    }
  }

  function handleProfileRedirect() {
    dispatch(updateProfilePreviewDialog(false)).then(() => {
      sendTrackingEvent({
        event: "CE_click-continue-to-profile",
      });
      if (router.pathname === "/profile") {
        return router.reload();
      }
      router.push("/profile#overview");
    });
  }

  function handleCloseSelectDialog() {
    sendTrackingEvent({
      event: "CE_click-back-no-resume-info-onboarding",
    });

    setShowSelectDialog(false);
  }

  function handleReuploadClick() {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  }

  async function handleFileChange(e: any) {
    e.preventDefault();
    setUploadingFile(true);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      if (parseFloat((file?.size / 1024 / 1024).toFixed(4)) > 2) {
        triggerSnackbarFunc({
          snackbarMessage: "Please make sure file size is below 2MB",
          severity: "error",
        });
        return setUploadingFile(false);
      }

      if (
        !(
          file.name?.includes(".docx") ||
          file.name?.includes(".doc") ||
          file.name?.includes(".pdf")
        )
      ) {
        triggerSnackbarFunc({
          snackbarMessage: "File should be .doc/.docx/.pdf",
          severity: "error",
        });
        return setUploadingFile(false);
      }

      convertBase64(file).then((cvFile) => {
        dispatch(
          newResumeUpload({
            resume: cvFile,
            state: "approved",
            canCancel: false,
            user_resume: user.resume,
            origin: "profile",
            autoGenerated: true,
          })
        ).then((resumeUploadRes: any) => {
          if (resumeUploadRes?.type === "UPDATE_USER_CV_FAILED") {
            triggerSnackbarFunc({
              snackbarMessage:
                resumeUploadRes?.payload?.message ??
                "Failed to update resume, please try again later.",
              severity: "error",
            });
            return setUploadingFile(false);
          } else {
            resumeSections.reFetch();
            return setUploadingFile(false);
          }
        });
      });
    }
  }

  useEffect(() => {
    dispatch(getIndustries());
  }, [dispatch]);

  return (
    <DialogStyled open={open} loading={resumeSections.progress < 100}>
      {resumeSections.progress < 100 ? (
        <LoadingContentWrapper loading={resumeSections.progress < 100}>
          <ContentTitle>Building your profile</ContentTitle>
          <ContentText>Hold on, this might take a few minutes</ContentText>
          <LinearProgressStyled
            variant="determinate"
            value={resumeSections.progress}
          />
        </LoadingContentWrapper>
      ) : (
        <Fragment>
          <CloseButton onClick={handleCloseDialog}>
            <CloseRoundedIconStyled />
          </CloseButton>
          <DialogTitleContainer>
            {!showSelectDialog && (
              <Fragment>
                <DialogTitleStyled>
                  {resumeSections.isEmpty
                    ? "Oops! Something went wrong"
                    : "We’ve pre-filled your profile for you"}
                </DialogTitleStyled>
                {!resumeSections.isEmpty && <BetaLabel>BETA</BetaLabel>}
              </Fragment>
            )}
          </DialogTitleContainer>

          <DialogContentWrapper>
            {showSelectDialog ? (
              <LoadingContentWrapper
                loading={resumeSections.progress < 100}
                gap={16}
              >
                <ContentTitle>
                  You haven’t select any information from your resume
                </ContentTitle>
                <ContentText>
                  Your profile will remain blank, are you sure you want to
                  proceed?
                </ContentText>
              </LoadingContentWrapper>
            ) : (
              <Fragment>
                {!resumeSections.isEmpty && (
                  <SelectContainer
                    onClick={resumeSections.onSelectAllResumeSections}
                  >
                    <SelectAllTitle>
                      Select information you’d like to showcase to employers.
                    </SelectAllTitle>
                    <SelectAllButtonContainer>
                      <RegularText>
                        {resumeSections.isAllResumeSectionsSelected()
                          ? "Deselect All"
                          : "Select All"}
                      </RegularText>
                      <SelectButton
                        selected={resumeSections.isAllResumeSectionsSelected()}
                      >
                        <CheckIconStyled />
                      </SelectButton>
                    </SelectAllButtonContainer>
                  </SelectContainer>
                )}
                <DialogContentContainer>
                  {resumeSections.isEmpty ? (
                    <LoadingContentWrapper
                      loading={resumeSections.progress < 100}
                      gap={32}
                    >
                      <ContentTitle leftAlign>
                        Uh-oh! It seems like we couldn't process your resume.
                      </ContentTitle>
                      <ContentText maxWidth={520} leftAlign>
                        We're sorry, but we couldn't parse the resume you
                        uploaded. It seems to be empty or in a format we can't
                        understand.
                      </ContentText>
                      <ContentText maxWidth={420} leftAlign>
                        You can either reupload a new resume or complete your
                        profile manually.
                      </ContentText>
                    </LoadingContentWrapper>
                  ) : (
                    <Fragment>
                      {resumeSections?.previewData?.workExperiencesStructured
                        ?.length > 0 && (
                        <Fragment>
                          <SectionTitle variant="h3">
                            Work Experience
                          </SectionTitle>
                          {resumeSections.previewData?.workExperiencesStructured?.map(
                            (workExperience, index) => (
                              <ResumeCard
                                key={index}
                                resumeSections={resumeSections}
                                type="workExperiencesStructured"
                                updateIndex={index}
                                selected={workExperience.selected}
                              />
                            )
                          )}
                        </Fragment>
                      )}
                      {resumeSections?.previewData?.educationsStructured
                        ?.length > 0 && (
                        <>
                          <SectionTitle variant="h3">Education</SectionTitle>
                          {resumeSections.previewData?.educationsStructured?.map(
                            (education, index) => (
                              <ResumeCard
                                key={index}
                                resumeSections={resumeSections}
                                type="educationsStructured"
                                updateIndex={index}
                                selected={education.selected}
                              />
                            )
                          )}
                        </>
                      )}
                      {resumeSections?.previewData?.skills?.length > 0 && (
                        <>
                          <SectionTitle variant="h3">Skills</SectionTitle>
                          <SkillCard
                            skills={resumeSections.previewData.skills}
                            selectedSkills={resumeSections.selectedSkills}
                            onChange={resumeSections.onToggleSkill}
                          />
                        </>
                      )}
                      {resumeSections?.previewData?.languages?.length > 0 && (
                        <>
                          <SectionTitle variant="h3">Languages</SectionTitle>
                          {resumeSections.previewData?.languages?.map(
                            (language, index) => (
                              <ResumeCard
                                key={index}
                                resumeSections={resumeSections}
                                type="languages"
                                updateIndex={index}
                                selected={language.selected}
                              />
                            )
                          )}
                        </>
                      )}
                      {resumeSections?.previewData?.certifications?.length >
                        0 && (
                        <>
                          <SectionTitle variant="h3">
                            Certificates & Training
                          </SectionTitle>
                          {resumeSections.previewData?.certifications?.map(
                            (cert, index) => (
                              <ResumeCard
                                key={index}
                                resumeSections={resumeSections}
                                type="certifications"
                                updateIndex={index}
                                selected={cert.selected}
                              />
                            )
                          )}
                        </>
                      )}
                    </Fragment>
                  )}
                </DialogContentContainer>
              </Fragment>
            )}
          </DialogContentWrapper>
          <DialogActionsStyled>
            {showSelectDialog ? (
              <Fragment>
                <WhiteButton onClick={handleCloseSelectDialog}>
                  Back
                </WhiteButton>
                <BlackButton onClick={handleProfileRedirect}>
                  Continue to Profile
                </BlackButton>
              </Fragment>
            ) : (
              <Fragment>
                {resumeSections?.isEmpty ? (
                  <Fragment>
                    <FileInput
                      ref={fileInputRef}
                      accept=".pdf,.docx,.doc"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <LoadingButtonStyled
                      loading={uploadingFile}
                      onClick={handleReuploadClick}
                    >
                      {uploadingFile ? "Uploading" : "Re-upload resume"}
                    </LoadingButtonStyled>
                    <CompleButton onClick={handleProfileRedirect}>
                      Complete profile
                    </CompleButton>
                  </Fragment>
                ) : (
                  <BlackButton onClick={handleAddToProfile}>
                    {resumeSections.hasResumeSectionSelected()
                      ? "Add"
                      : "Continue"}{" "}
                    to Profile
                  </BlackButton>
                )}
              </Fragment>
            )}
          </DialogActionsStyled>
        </Fragment>
      )}
    </DialogStyled>
  );
}

function ResumeCard(props: ResumeCardProps) {
  const { resumeSections, type, updateIndex, selected } = props;
  const [editMode, setEditMode] = useState(false);
  let itemType: string;

  // NOTE: Provide preview & edit data based on the TYPE given
  function resumeData(
    data: "previewData" | "editData"
  ): WorkExperience | Education | Language | Certification {
    switch (type) {
      case "certifications":
        itemType = "certificates_training";
        return resumeSections?.[data]?.certifications?.[updateIndex];
      case "educationsStructured":
        itemType = "education";
        return resumeSections?.[data]?.educationsStructured?.[updateIndex];
      case "languages":
        itemType = "languages";
        return resumeSections?.[data]?.languages?.[updateIndex];
      case "workExperiencesStructured":
        itemType = "work_experience";
        return resumeSections?.[data]?.workExperiencesStructured?.[updateIndex];
    }
  }

  function handleOpenEditMode(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    sendTrackingEvent({
      event: "CE_click-edit-profile-onboarding",
      item: itemType,
    });

    setEditMode(true);
  }

  function handleCloseButtonClick() {
    setEditMode(false);
    resumeSections.undoEditData();
  }

  function handleSave() {
    const saveSuccess = resumeSections.onSaveEditData(type, updateIndex);
    if (saveSuccess) {
      setEditMode(false);
    }
  }

  function handleSelectResumeSection() {
    resumeSections.onSelectResumeSection(type, updateIndex);
  }

  function handleUpdateResumeSection(updateField: string, input: any) {
    resumeSections.onDataChange(input, type, updateField, updateIndex);
  }

  function handleDeleteData() {
    resumeSections.onDataDelete(type, updateIndex);
  }

  return (
    <Fragment>
      {editMode ? (
        <EditCard
          type={type}
          data={resumeData("editData")}
          onCloseEditMode={handleCloseButtonClick}
          updateResumeSection={(updateField, input) =>
            handleUpdateResumeSection(updateField, input)
          }
          saveEditResumeSection={handleSave}
          canDelete={type === "languages"}
          onDelete={handleDeleteData}
        />
      ) : (
        <PreviewCard
          type={type}
          data={resumeData("previewData")}
          selected={selected}
          onOpenEditMode={handleOpenEditMode}
          selectResumeSection={handleSelectResumeSection}
          shouldShowError={resumeSections.shouldShowError}
        />
      )}
    </Fragment>
  );
}

function SkillCard(props: SkillCardProps) {
  const { selectedSkills, skills, onChange } = props;

  return (
    <SectionCardWrapper disableHover noPaddingBottom>
      <SkillCardContainer>
        <ToggleButtonGroupStyled
          value={skills}
          onChange={onChange}
          aria-label="skill selection"
        >
          {skills?.length > 0 &&
            skills?.map((skill, index) => (
              <SkillToggleButton
                key={index}
                selected={selectedSkills?.includes(skill)}
                value={skill}
                aria-label={skill}
              >
                {skill}
              </SkillToggleButton>
            ))}
        </ToggleButtonGroupStyled>
      </SkillCardContainer>
    </SectionCardWrapper>
  );
}

export function MultilineTextarea(props: MultilineTextareaProps) {
  const { onUpdate, maxLength, defaultValue } = props;
  const [value, setValue] = useState<any>("");

  useEffect(() => {
    setValue(defaultValue);
  }, []);

  function handleChange(e: any) {
    if (e.target.value?.length > maxLength) return;
    if (value.length === 0) {
      const newValue = "•" + e.target.value;
      setValue(newValue);
      onUpdate(newValue);
    } else {
      setValue(e.target.value);
      onUpdate(e.target.value);
    }
  }

  function handleEnterKey(e: any) {
    const position = e.target.selectionStart;
    const endString = value?.charAt(position);

    if (e.key === "Enter" && endString === "") {
      setValue(e.target.value + "•");
    }
  }

  return (
    <EditAboutContentContainer>
      <AboutTextField
        {...props}
        value={value}
        multiline
        maxRows={6}
        type={"text"}
        onChange={handleChange}
        onKeyUp={handleEnterKey}
      />
      <CharacterCount
        disable={(props.defaultValue as string)?.length >= props?.maxLength}
        error={props?.error}
      >
        {(props.defaultValue as string)?.length}/{props?.maxLength}
      </CharacterCount>
      {props?.error && (
        <InputErrorText>Description length exceeds maximum</InputErrorText>
      )}
    </EditAboutContentContainer>
  );
}

export function CustomInput(props: CustomInputProps) {
  const { onUpdate, defaultValue } = props;
  const [value, setValue] = useState<any>("");

  useEffect(() => {
    setValue(defaultValue);
  }, []);

  function handleChange(e: any) {
    setValue(e.target.value);
    onUpdate(e.target.value);
  }

  return (
    <Fragment>
      <InputContainer>
        <InputStyled {...props} value={value} onChange={handleChange} />
        {props?.error && <ErrorIcon />}
      </InputContainer>
      {props?.error && <InputErrorText>{props?.helperText}</InputErrorText>}
    </Fragment>
  );
}
