import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import ShieldExclamationIcon from "@heroicons/react/24/outline/ShieldExclamationIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const DialogContainer = styling("div")((props) => ({
  position: "sticky",
  top: "64px",

  background: "rgba(253, 243, 223, 1)",

  display: props.show ? "flex" : "none",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",

  width: "100%",
  padding: "1.25rem 1rem",
  zIndex: "1000",

  "@media (max-width: 1280px)": {
    top: props.isHomepage ? "52px" : "60px",
  },

  "@media (max-width: 640px)": {
    top: "60px",
  },
}));

export const TextContainer = styling("p")({
  color: Color.black,

  fontSize: "1rem",
  letterSpacing: "0.15px",
  textAlign: "center",
  textWrap: "wrap",

  margin: "0",

  "@media (max-width: 1279px)": {
    fontSize: "0.875rem",
    letterSpacing: "0.112px",
  },
});

export const DialogContent = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.25rem",

  margin: "0 0 0 auto",
});

export const MobileContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",

  padding: "0 1.25rem",
});

export const InnerContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.25rem",
});

export const ImageStyled = styling("img")({
  margin: "0.25rem",
});

export const PurpleText = styling("span")({
  color: Color.hiredlyPurple,
  fontWeight: "700",
  display: "inline",
});

export const BoldText = styling("span")({
  fontWeight: "700",
});

export const CloseButton = styling("button")({
  background: "none",
  border: "none",
  padding: "0",
  outline: "none",
  margin: "0 0 0 auto",

  "&:hover": {
    cursor: "pointer",
  },
});

export const CloseIcon = styling(XMarkIcon)({
  width: "24px",
  height: "24px",
  color: Color.black,
});

export const WarningIcon = styling(ExclamationTriangleIcon)({
  width: "24px",
  height: "24px",
  color: Color.hiredlyPurple,
});

export const ShieldWarningIcon = styling(ShieldExclamationIcon)({
  width: "24px",
  height: "24px",
  minWidth: "24px",
  minHeight: "24px",
  color: "rgba(228, 189, 50, 1)",
});
