import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import zxcvbn from "zxcvbn";
import Color from "../../../assets/colors";
import SocialLoginButton from "../../../components/onboarding/SocialLoginButton/SocialLoginButton";
import * as config from "../../../config/config";
import { setItem } from "../../../helpers/data_management";
import { posthogSignup } from "../../../helpers/posthog_management";
import { triggerSnackBar } from "../../../helpers/snackbar_management";
import {
  sendTrackingEvent,
  signupTracking,
} from "../../../helpers/tracking_management";
import {
  updateJobAlertVisitorEmailDialog,
  updateResumeConfirmationDialog,
} from "../../../redux/actions/dialog_action";
import {
  hideSnackBar,
  showSnackBar,
} from "../../../redux/actions/snackbar_action";
import { register, socialMediaLogin } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as types from "../../../redux/types/user_type";
import {
  ActionLabelStyled,
  BoxContainer,
  ButtonContainer,
  CheckStyled,
  CheckboxStyled,
  CheckedIcon,
  CircularProgressStyled,
  CustomDivider,
  CustomDividerContainer,
  DialogContentStyled,
  DisclaimerContainer,
  EmailSignUpFormContainer,
  EmailSignUpFormContent,
  FormTitle,
  GoogleButton,
  InfoIconStyled,
  LabelDivider,
  LabelStyled,
  LinkedInButton,
  PasswordContainer,
  PasswordInfoText,
  PasswordStrength,
  PasswordStrengthBar,
  PasswordStrengthInfo,
  PasswordStrengthText,
  ReferralLabel,
  SSOSignUpContainer,
  SignUpButton,
  SocialSpinnerContainer,
  StyledLink,
  TextInput,
  TextStyled,
  UncheckedIcon,
  VisibilityIconStyled,
  VisibilityOffIconStyled,
} from "./styles";

function TextInputFunction(props) {
  const {
    label,
    handleChange,
    type,
    email,
    password,
    onKeyDown,
    passwordShown,
    togglePassword,
    registering,
  } = props;

  return (
    <Fragment>
      <TextInput
        disabled={registering}
        label={type == "email" ? "Email" : "Password"}
        value={type == "email" ? email : password}
        type={`${type}`}
        onChange={handleChange}
        id={`filled-required-${label}`}
        variant="filled"
        password_shown={passwordShown ? "true" : "false"}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="start"
              style={{ display: `${type == "email" ? "none" : "flex"}` }}
            >
              {passwordShown ? (
                <VisibilityIconStyled onClick={togglePassword} />
              ) : (
                <VisibilityOffIconStyled onClick={togglePassword} />
              )}
            </InputAdornment>
          ),
        }}
        onKeyDown={onKeyDown}
      />
    </Fragment>
  );
}

let isRegistering = false;

function getAppId(ssoLogin) {
  switch (ssoLogin) {
    case "Continue with Facebook": {
      return process.env.FACEBOOK_APP_ID;
    }
    case "Continue with Google": {
      return process.env.GOOGLE_CLIENT_ID;
    }
    case "Continue with Linkedin": {
      return process.env.LINKEDIN_ID;
    }
  }
}

function SignUp(props) {
  const router = useRouter();
  const userEmail =
    store.getState().user.user.email || store.getState().jobs.jobAlertEmail;
  const [values, setValues] = useState({
    email: router.query.hiredlyx ? router.query.email : "",
    password: "",
    pdpaAccepted: false,
    error: "",
    loading: false,
    message: "",
  });
  const { email, password, pdpaAccepted } = values;

  // for posthog tracking
  const fypTab = Number(useSelector((state) => state.jobs.fypTab));
  const signUpOrigin = useSelector((state) => state?.navbar?.signUpOrigin);
  const selectedCompany = useSelector(
    (state) => state?.companies?.selectedCompany
  );
  const selectedJob = useSelector((state) => state?.jobs?.job);
  const currentCompany =
    Object.keys(selectedJob).length > 0 ? selectedJob.company : selectedCompany;

  const currentUrl = router.asPath;

  // For Google login
  const [hideGoogleButton, setHideGoogleButton] = useState(false);

  const onFailure = (response) => {
    if (
      response.details?.includes(
        "Cookies are not enabled in current environment."
      )
    ) {
      setHideGoogleButton(true);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const params = {
        _provider: "google",
        _token: {
          accessToken: tokenResponse.access_token,
        },
      };
      handleSocialLogin(params);
    },
    onError: (errorResponse) => onFailure(errorResponse),
  });

  // Pre-fill sign up email if log in email doesn't exist
  useEffect(() => {
    if (userEmail) {
      setValues({ email: userEmail });
    }
  }, [userEmail]);

  const [pdpaChecked, setPdpaChecked] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");

  const [showFbNoEmail, setShowFbNoEmail] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  // Function to trigger job seekers who log in via email
  const triggerCustomEventEmailLogin = () => {
    sendTrackingEvent({ event: "email-login" });
  };

  // Function to trigger job seekers who sign up for the first time via email
  const triggerCustomEventFirstTimeEmailSignup = () => {
    if (signUpOrigin === "job_recommendation" && fypTab === 1) {
      sendTrackingEvent({
        event: "CE_sign_up_rec",
        method: "email",
        origin: "job_recommendation",
      });
    } else {
      sendTrackingEvent({
        event: "first-time-signup",
      });
    }

    posthogSignup(selectedJob, currentCompany, signUpOrigin, "email");
  };

  // Function to trigger job seekers who log in via SSO
  const triggerCustomEventSsoLogin = (provider) => {
    sendTrackingEvent({
      event: `${provider == "google" ? "google-login" : "facebook-login"}`,
    });
  };

  // Function to trigger job seekers who sign up for the first time via SSO
  const triggerCustomEventSsoFirstTimeSignup = (provider) => {
    if (signUpOrigin === "job_recommendation" && fypTab === 1) {
      sendTrackingEvent({
        event: "CE_sign_up_rec",
        method: provider === "google" ? "google" : "facebook",
        origin: "job_recommendation",
      });
    } else {
      sendTrackingEvent({
        event: `${
          provider == "google"
            ? "google-sso-first-time-signup"
            : "facebook-sso-first-time-signup"
        }`,
      });
    }
    posthogSignup(
      selectedJob,
      currentCompany,
      signUpOrigin,
      provider === "google" ? "google" : "facebook"
    );
  };

  function handleCheck() {
    setPdpaChecked(!pdpaChecked);
    setValues({ ...values, pdpaAccepted: !values.pdpaAccepted });
  }

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (email == "" || password == "") {
      const cParams = {
        message: "Please key in both email and password to sign up",
        severity: "error",
      };
      triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);

      return;
    }

    if (password?.length < 8) {
      const cParams = {
        message: "Please ensure password is minimum 8 characters long",
        severity: "error",
      };

      triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);

      return;
    }

    if (!pdpaAccepted) {
      setPdpaChecked(true);
      return;
    }

    if (isRegistering) return;

    isRegistering = true;

    let urlParams = document.cookie
      .split("; ")
      .find((row) => row.startsWith("params="))
      ?.toString()
      .replace("params=", "");
    let cEmail = email;
    let symbolIndex = cEmail.indexOf("@");
    let cName = cEmail.substring(0, symbolIndex);
    let mrpId = document.cookie
      .split("; ")
      .find((row) => row.startsWith("mrpId="))
      ?.toString()
      .replace("mrpId=", "");

    const { signupMedium, signupSource } = signupTracking(
      currentUrl,
      signUpOrigin,
      selectedJob,
      selectedCompany
    );

    let params = {
      name: cName,
      email: email,
      password: password,
      pdpaAccepted: pdpaAccepted,
      urlParams: urlParams, // To track users who sign up from an advertisement
      referralToken: null, // To track users who sign up via Workanda campaign
      mrpParentId: mrpId, // To track referrer id for talent referral program
      signupMedium: signupMedium,
      signupSource: signupSource,
    };

    props
      .register(params)
      .then((response) => {
        isRegistering = false;
        if (response.type === types.REGISTER_ACCOUNT_SUCCEED) {
          let userId = response.user.id;
          let userName = response.user.name;
          let userEmail = response.user.email;

          // check if visitor job alert exists and store to local storage
          if (store.getState().jobs.visitorJobAlert) {
            setItem(
              "visitorJobAlert",
              store.getState().jobs.visitorJobAlert.toString()
            );
          }

          // check if visitor sign-up from job alert email mailer
          const visitorUrl = window.location.search;
          if (visitorUrl.includes("?visitor-sign-up")) {
            window.sessionStorage.setItem("visitorJobAlertSignUp", "true");
          }

          if (store.getState().user.isDropCV) {
            // Google enhanced conversion tracking for sign ups on drop resume page
            sendTrackingEvent({
              event: "event_enhanced_conversion",
              enhanced_conversion_data: {
                email: `${userEmail}`,
                phone_number: ``,
                last_name: ``,
                first_name: `${userName}`,
              },
            });

            Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
            Cookies.set("isRegistered", userId ? true : false, {
              expires: 365,
            }); // Check if user is registered/logged in and store cookie for CleverTap

            props.handleClose();
            triggerCustomEventFirstTimeEmailSignup();
            triggerCustomEventEmailLogin();
            router.reload();
          } else {
            try {
              // Google enhanced conversion tracking for sign ups
              sendTrackingEvent({
                event: "event_enhanced_conversion",
                enhanced_conversion_data: {
                  email: `${userEmail}`,
                  phone_number: ``,
                  last_name: ``,
                  first_name: `${userName}`,
                },
              });

              Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
              Cookies.set("isRegistered", userId ? true : false, {
                expires: 365,
              }); // Check if user is registered/logged in and store cookie for CleverTap

              triggerCustomEventFirstTimeEmailSignup();
              triggerCustomEventEmailLogin();
            } catch (error) {
              //
            }

            props.handleClose();
            router.reload();
          }
        } else if (response.message == "account exist") {
          // Only allow valid email addresses to sign up
          const validateEmailFormat = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          );

          let cParams = {
            message: `${
              validateEmailFormat.test(email)
                ? "Account already exist"
                : "Email is not valid"
            }`,
            severity: "error",
          };

          triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
        }
        props.updateJobAlertVisitorEmailDialog(false);
      })
      .catch((error) => {
        isRegistering = false;
      });
  };

  const handleChange = (name) => (e) => {
    // Password validation strength check
    const password = zxcvbn(e.target.value);

    if (name === "password") {
      if (!e.target.value || e.target.value === "") {
        setPasswordStrength("");
      } else {
        if (password.score > 2) {
          setPasswordStrength("strong");
        } else if (password.score === 2) {
          setPasswordStrength("medium");
        } else {
          setPasswordStrength("poor");
        }
      }
    }

    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const handleSocialLogin = (response) => {
    let provider = response._provider;

    setShowFbNoEmail(false);

    // To track users who sign up from an advertisement
    let urlParams = document.cookie
      .split("; ")
      .find((row) => row.startsWith("params="))
      ?.toString()
      .replace("params=", "");

    const { signupMedium, signupSource } = signupTracking(
      currentUrl,
      signUpOrigin,
      selectedJob,
      selectedCompany
    );

    let params = {
      provider: provider,
      accessToken: response._token.accessToken,
      urlParams: urlParams,
      signupMedium: signupMedium,
      signupSource: signupSource,
    };

    props.socialMediaLogin(params).then((response) => {
      if (
        response.type === types.SOCIAL_LOGIN_FAILED &&
        response.payload?.noEmail === true
      ) {
        // show the fb no email popup
        return setShowFbNoEmail(true);
      } else if (response.type === types.SOCIAL_LOGIN_SUCCEED) {
        let userId = response.payload.user.id;
        let userName = response.payload.user.name;
        let userEmail = response.payload.user.email;

        // Check if visitor job alert exists and store to local storage
        if (store.getState().jobs.visitorJobAlert) {
          setItem(
            "visitorJobAlert",
            store.getState().jobs.visitorJobAlert.toString()
          );
        }

        Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
        Cookies.set("isRegistered", userId ? true : false, {
          expires: 365,
        }); // Check if user is registered/logged in and store cookie for CleverTap

        localStorage.setItem("job_preference_clicked", 0);

        if (store.getState().user.isDropCV) {
          if (response.payload && response.payload.newUser) {
            // Google enhanced conversion tracking for SSO sign ups on drop resume page
            sendTrackingEvent({
              event: "event_enhanced_conversion",
              enhanced_conversion_data: {
                email: `${userEmail}`,
                phone_number: ``,
                last_name: ``,
                first_name: `${userName}`,
              },
            });

            triggerCustomEventSsoFirstTimeSignup(provider);
            props.updateResumeConfirmationDialog(true);
          }
          router.reload();
        } else if (response.payload && response.payload.newUser) {
          // Google enhanced conversion tracking for SSO sign ups
          sendTrackingEvent({
            event: "event_enhanced_conversion",
            enhanced_conversion_data: {
              email: `${userEmail}`,
              phone_number: ``,
              last_name: ``,
              first_name: `${userName}`,
            },
          });

          triggerCustomEventSsoFirstTimeSignup(provider);
          props.handleClose();
        } else {
          triggerCustomEventSsoLogin(provider);
          if (
            response.payload.user.resume === "" &&
            !response.payload.user.onboardingComplete
          ) {
            props.handleClose();
            return props.updateResumeConfirmationDialog(true);
          }
          router.reload();
        }
      }
      props.updateJobAlertVisitorEmailDialog(false);
    });
  };

  const handleSocialLoginFailure = (error) => {
    console.error("social login error:", error);
  };

  const handleLinkedInButtonClick = () => {
    let oauthUrl = new URL("https://www.linkedin.com/oauth/v2/authorization");
    oauthUrl.searchParams.append("response_type", "code");
    oauthUrl.searchParams.append("client_id", process.env.LINKEDIN_ID);
    oauthUrl.searchParams.append(
      "redirect_uri",
      process.env.LINKEDIN_REDIRECT_URI
    );
    oauthUrl.searchParams.append("scope", process.env.LINKEDIN_SCOPE);

    window.location = oauthUrl;

    // check if visitor job alert exists and store to local storage
    if (store.getState().jobs.visitorJobAlert) {
      setItem(
        "visitorJobAlert",
        store.getState().jobs.visitorJobAlert.toString()
      );
    }
    props.updateJobAlertVisitorEmailDialog(false);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(null);
    }
  };

  const getProvider = (ssoLogin) => {
    switch (ssoLogin) {
      case "Continue with Facebook": {
        return "facebook";
      }
      case "Continue with Google": {
        return "google";
      }
      case "Continue with Linkedin": {
        return "facebook";
      }
    }
  };

  return (
    <Fragment>
      <DialogContentStyled>
        <FormTitle>Sign Up</FormTitle>
        <EmailSignUpFormContainer>
          <form autoComplete="off">
            <EmailSignUpFormContent>
              <TextInputFunction
                registering={props.registering}
                label={"Email"}
                type="email"
                email={email}
                handleChange={handleChange("email")}
                onKeyDown={onKeyDown}
              />
              <PasswordContainer item>
                <TextInputFunction
                  registering={props.registering}
                  label={"Password"}
                  type={passwordShown ? "text" : "password"}
                  password={password}
                  handleChange={handleChange("password")}
                  onKeyDown={onKeyDown}
                  passwordShown={passwordShown}
                  togglePassword={togglePassword}
                />
                <PasswordStrengthBar>
                  <PasswordStrength
                    password_strength={passwordStrength}
                  ></PasswordStrength>
                </PasswordStrengthBar>
                {passwordStrength ? (
                  <PasswordStrengthInfo>
                    <PasswordInfoText>
                      Your password is{" "}
                      <PasswordStrengthText
                        password_strength={passwordStrength}
                      >
                        {passwordStrength}
                      </PasswordStrengthText>
                    </PasswordInfoText>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            zIndex: 1600,
                          },
                        },
                      }}
                      placement="top"
                      arrow
                      title={`Use 8 or more characters with a mix of letters, numbers & symbols`}
                    >
                      <InfoIconStyled />
                    </Tooltip>
                  </PasswordStrengthInfo>
                ) : (
                  <PasswordStrengthInfo>
                    <PasswordInfoText>Password suggestion</PasswordInfoText>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            zIndex: 1600,
                          },
                        },
                      }}
                      placement="top"
                      arrow
                      title={`Use 8 or more characters with a mix of letters, numbers & symbols`}
                    >
                      <InfoIconStyled />
                    </Tooltip>
                  </PasswordStrengthInfo>
                )}
              </PasswordContainer>
              <BoxContainer flexDirection={"column"} gap={"0.25rem"}>
                <BoxContainer alignItems={"flex-start"} gap={"0.5rem"}>
                  <CheckboxStyled
                    icon={<UncheckedIcon onError={pdpaChecked} />}
                    onChange={handleCheck}
                    value={pdpaAccepted}
                    checked={pdpaAccepted}
                    checkedIcon={
                      <CheckedIcon>
                        <CheckStyled />
                      </CheckedIcon>
                    }
                  />
                  <LabelStyled checkbox="true">
                    I accept Hiredly’s{" "}
                    <StyledLink href="/privacy-policy" checkbox="true">
                      Privacy Policy
                    </StyledLink>{" "}
                    and{" "}
                    <StyledLink href="/terms-and-conditions" checkbox="true">
                      Terms & Conditions
                    </StyledLink>{" "}
                  </LabelStyled>
                </BoxContainer>
                <BoxContainer>
                  {pdpaChecked && !pdpaAccepted ? (
                    <TextStyled
                      color={Color.error}
                      fontSize={"0.6875rem"}
                      fontWeight={500}
                      lineHeight={"20px"}
                      letterSpacing={"0.15px"}
                    >
                      You must accept the terms and conditions.
                    </TextStyled>
                  ) : null}
                </BoxContainer>
              </BoxContainer>
            </EmailSignUpFormContent>
          </form>
        </EmailSignUpFormContainer>
        <SignUpButton
          onClick={handleSubmit}
          variant="contained"
          disabled={props.registering}
        >
          create account
        </SignUpButton>
        <ButtonContainer>
          <LabelStyled caption="true">Already have an account?</LabelStyled>
          <ActionLabelStyled
            caption="true"
            className={"sso-button"}
            onClick={() => props.handleClickSignIn()}
            color="primary"
            $log_in_button
          >
            log in
          </ActionLabelStyled>
        </ButtonContainer>
        {router.asPath.includes("referral_new_sign_up") ? (
          <ReferralLabel subtitle="true">
            Upon sign up, you will receive an invitation email to be a Referrer
            and you can start inviting your friends
          </ReferralLabel>
        ) : null}
        <Fragment>
          <CustomDividerContainer>
            <CustomDivider />
            <LabelDivider>Or continue with</LabelDivider>
            <CustomDivider />
          </CustomDividerContainer>
          <SSOSignUpContainer item>
            {props.socialLoggingIn || props.registering ? (
              <SocialSpinnerContainer>
                <CircularProgressStyled />
              </SocialSpinnerContainer>
            ) : (
              <Fragment>
                <GoogleButton
                  onClick={googleLogin}
                  $hide_button={hideGoogleButton ? "true" : "false"}
                >
                  <img
                    src={
                      config.assetDomain +
                      "/images/hiredly/google-logo-login.svg"
                    }
                    alt="google"
                  />
                </GoogleButton>
                <LinkedInButton onClick={handleLinkedInButtonClick}>
                  <img
                    src={
                      config.assetDomain +
                      "/images/hiredly/linkedin-logo-login.svg"
                    }
                    alt="linkedin"
                  />
                </LinkedInButton>
                <SocialLoginButton
                  provider={getProvider("Continue with Facebook")}
                  appId={getAppId("Continue with Facebook")}
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                >
                  <img
                    src={
                      config.assetDomain +
                      "/images/hiredly/facebook-logo-login.svg"
                    }
                    alt="facebook"
                  />
                </SocialLoginButton>
              </Fragment>
            )}
          </SSOSignUpContainer>
        </Fragment>

        <DisclaimerContainer>
          <LabelStyled disclaimer="true">
            By signing-up with the above, you agree to Hiredly’s{" "}
            <StyledLink href="/privacy-policy">Privacy Policy</StyledLink> and{" "}
            <StyledLink href="/terms-and-conditions">
              Terms & Conditions
            </StyledLink>{" "}
            .
          </LabelStyled>
        </DisclaimerContainer>
      </DialogContentStyled>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    registering: state.user.registering,
    socialLoggingIn: state.user.socialLoggingIn,
    signUpText: state.navbar.signUpText,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: bindActionCreators(register, dispatch),
    hideSnackBar: bindActionCreators(hideSnackBar, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    socialMediaLogin: bindActionCreators(socialMediaLogin, dispatch),
    updateJobAlertVisitorEmailDialog: bindActionCreators(
      updateJobAlertVisitorEmailDialog,
      dispatch
    ),
    updateResumeConfirmationDialog: bindActionCreators(
      updateResumeConfirmationDialog,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
