import api, { strapiCall } from "../../utils/api";
import { ARTICLE_PER_PAGE } from "../reducers/advice_reducer";
import * as types from "../types/advice_type";

const requestArticle =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `mutation {
        sendArticleRequest(input: {email: "${params.email}", requested_article_title: "${params.articleTitle}"}) {
         success
        }
      }     
    `,
    };

    return api
      .apiCall("/api/v2/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const success = response.data?.data?.sendArticleRequest?.success;
          return { success };
        }
        return { success: false };
      });
  };

const clearPagination =
  (params = {}) =>
  async (dispatch) => {
    return dispatch({ type: types.CLEAR_PAGINATION });
  };

const getBlogs =
  (params = {}) =>
  async (dispatch, getState) => {
    try {
      let startAt = 0;
      const currentPagination = getState().advice?.pagination;
      const currentPage = currentPagination?.page;
      const totalArticles = currentPagination?.total;
      if (currentPage) {
        startAt = currentPage * ARTICLE_PER_PAGE;
      }
      if (totalArticles && startAt && startAt >= totalArticles) {
        // Already reached the end
        return;
      }

      dispatch({ type: types.FETCHING_BLOGS });
      const categoryId = params?.categoryId;
      const subcategoryId = params?.subcategoryId;
      const searchQuery = params?.searchQuery;

      const payload = {
        query: `
          query {
            articles(
              filters: {
                and: [
                  {
                    articleCategories: { 
                      categoryId: { contains: "${categoryId ?? ""}" }
                    }
                  },
                  {
                    articleSubcategories: {
                      subcategoryId: { contains: "${subcategoryId ?? ""}" }
                    }
                  },
                  {
                    title: { containsi: "${searchQuery ?? ""}" }
                  },
                  { active: { eq: true } },
                ]
              },
              pagination: {
               start: ${startAt ?? 0}, limit: ${ARTICLE_PER_PAGE}
              },
              sort: "updatedAt:desc"
            ) {
              data {
                attributes {
                  author {
                    data {
                      attributes {
                        fullName
                      }
                    }
                  }
                  coverImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  content
                  title
                  slug
                  excerpt
                  createdAt
                  updatedAt
                  metaTitle
                  metaDescription
                  articleId
                  articleCategories {
                    data {
                      attributes {
                        category
                      }
                    }
                  }
                  articleSubcategories {
                    data {
                      attributes {
                        subcategory
                      }
                    }
                  }
                }
              }
              meta {
                pagination {
                  total
                  pageCount
                  page
                  pageSize
                }
              }
            }
          }
        `,
      };

      const response = await strapiCall(payload);

      if (response.status === 200) {
        const { meta } = response.data?.data?.articles || {};
        const { data } = response.data?.data?.articles || [];
        if (data) {
          let newArticles = [];
          const currentArticles = getState().advice?.blogs;
          if (currentArticles && currentArticles.length > 0) {
            if (currentPagination) {
              // Already has first load, append new articles to existing
              newArticles = [...currentArticles, ...data];
            }
          } else {
            // First load
            newArticles = data;
          }
          return dispatch({
            type: types.FETCHING_BLOGS_SUCCEEDED,
            payload: { newArticles, meta },
          });
        }
      }
    } catch (error) {
      // Error handling
    }
  };

const getArticleCategories =
  (params = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: types.FETCHING_CATEGORIES });
      const payload = {
        query: `
          query {
            articleCategories(sort: "order:asc") {
              data {
                attributes {
                  categoryId
                  category
                  articleSubcategories(sort: "subcategory:asc") {
                    data {
                      attributes {
                        subcategory
                        subcategoryId
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      };

      const response = await strapiCall(payload, true);
      if (response.status === 200) {
        const { data } = response.data?.data?.articleCategories || [];
        if (data) {
          return dispatch({
            type: types.FETCHING_CATEGORIES_SUCCEEDED,
            payload: data,
          });
        }
      }
    } catch (error) {}
  };

const getSingleBlog = async (slug) => {
  try {
    const payload = {
      query: `
        query {
          articles(filters: { slug: { eq: "${slug}" } }) {
            data {
              attributes {
                author {
                  data {
                    attributes {
                      fullName
                    }
                  }
                }
                coverImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                content
                title
                slug
                excerpt
                createdAt
                updatedAt
                metaTitle
                metaDescription
                articleCategories {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                articleSubcategories {
                  data {
                    attributes {
                      subcategory
                    }
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  metaImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  metaRobots
                  canonicalURL
                  keywords
                  metaSocial {
                    socialNetwork
                    title
                    description
                    image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    };

    const response = await strapiCall(payload, true);
    if (response.status === 200) {
      const { data } = response.data?.data?.articles || [];
      if (data) {
        return data[0];
      } else {
        return null;
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};

const getAdvicePageAd = async (params = {}) => {
  const payload = {
    query: `
      query {
        malaysia {
          data {
            attributes {
              advicePageAd {
                data {
                  attributes {
                    title
                    url
                    ctaButtonText
                    image {
                      data {
                        attributes {
                          alternativeText
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { data } =
      response.data?.data?.malaysia?.data?.attributes?.advicePageAd || {};

    if (data) {
      return data;
    }
    return null;
  }
};

const updateSearchQuery =
  (params = "") =>
  async (dispatch, getState) => {
    return dispatch({
      type: types.UPDATE_SEARCH_QUERY,
      payload: params,
    });
  };

const updateStaticSearchQuery =
  (params = "") =>
  async (dispatch, getState) => {
    return dispatch({
      type: types.UPDATE_STATIC_SEARCH_QUERY,
      payload: params,
    });
  };

const updateSelectedCategory =
  (params = {}) =>
  async (dispatch, getState) => {
    return dispatch({
      type: types.UPDATE_SELECTED_CATEGORY,
      payload: params,
    });
  };

const updateSelectedSubcategory =
  (params = {}) =>
  async (dispatch, getState) => {
    return dispatch({
      type: types.UPDATE_SELECTED_SUBCATEGORY,
      payload: params,
    });
  };

/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getBlogsForSitemap = async (params = {}) => {
  const advice_page = true;
  const payload = {
    query: `query getBlogsAdvicePage {
      posts(first: 20) {
        edges {
          node {
            title
            id
            slug
          }
        }
      }
    }`,
  };

  const response = await api.apiCall(
    "/graphql",
    params,
    payload,
    null,
    advice_page
  );
  if (response.status === 200) {
    const blogs = response.data?.data?.posts?.edges;

    if (blogs != null) {
      return blogs;
    }
    if (blogs === null) {
      return null;
    }
    return null;
  }
};

const getBlogsWithoutRedux = async (params = {}) => {
  const payload = {
    query: `
          query {
            articles(
              filters: {
                and: [
                  {
                    articleCategories: { 
                      categoryId: { contains: "${params?.categoryId ?? ""}" }
                    }
                  },
                  {
                    articleSubcategories: {
                      subcategoryId: { contains: "${
                        params?.subcategoryId ?? ""
                      }" }
                    }
                  },
                  {
                    title: { containsi: "${params?.searchQuery ?? ""}" }
                  },
                  { active: { eq: true } },
                ]
              },
              pagination: {
               start: 0, limit: 60
              },
              sort: "updatedAt:desc"
            ) {
              data {
                attributes {
                  author {
                    data {
                      attributes {
                        fullName
                      }
                    }
                  }
                  coverImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  content
                  title
                  slug
                  excerpt
                  createdAt
                  updatedAt
                  metaTitle
                  metaDescription
                  articleId
                  articleCategories {
                    data {
                      attributes {
                        category
                      }
                    }
                  }
                  articleSubcategories {
                    data {
                      attributes {
                        subcategory
                      }
                    }
                  }
                }
              }
              meta {
                pagination {
                  total
                  pageCount
                  page
                  pageSize
                }
              }
            }
          }
        `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { meta } = response.data?.data?.articles || {};
    const { data } = response.data?.data?.articles || null;

    if (data != null) {
      return { data, meta };
    }

    if (data === null) {
      return { blogNotFound: true, meta };
    }
  }
  return { blogNotFound: true };
};

export {
  clearPagination,
  getAdvicePageAd,
  getArticleCategories,
  getBlogs,
  getBlogsForSitemap,
  getBlogsWithoutRedux,
  getSingleBlog,
  requestArticle,
  updateSearchQuery,
  updateSelectedCategory,
  updateSelectedSubcategory,
  updateStaticSearchQuery,
};
