import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updateUserProfileDrawerIndex } from "../../../redux/actions/user_action";
import {
  BlankContainer,
  BottomContent,
  BriefcaseIconStyled,
  CloseIconStyled,
  IconButtonStyled,
  MiddleRowContainer,
  NotificationPromptContainer,
  SpanBold,
  TopRowContainer,
  UpdateButton,
} from "./styles";

const SharedJobPreferenceNotification = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { popupCount, openNotification, setOpenNotification } = props;

  const CloseNotification = () => {
    sendTrackingEvent({
      event: "CE_job-preference-popup-close",
    });

    setOpenNotification(false);
    localStorage.setItem("job_preference_clicked", popupCount + 1);
  };
  const navigateToProfile = () => {
    sendTrackingEvent({
      event: "CE_job-preference-popup-lets-go",
    });

    localStorage.setItem("job_preference_clicked", popupCount + 1);

    setTimeout(() => {
      setOpenNotification(false);
    }, 500);

    dispatch(updateUserProfileDrawerIndex(8));
    router.push("/profile#job-preferences");
  };

  return (
    <NotificationPromptContainer
      container
      direction="column"
      $close={openNotification}
    >
      <TopRowContainer>
        <IconButtonStyled>
          <CloseIconStyled onClick={CloseNotification} />
        </IconButtonStyled>
      </TopRowContainer>

      <MiddleRowContainer>
        <BlankContainer>
          <BriefcaseIconStyled />
        </BlankContainer>
        <BlankContainer>
          We’ve expanded <SpanBold>Job Specialisations</SpanBold> and moved your
          selections to the new list. <br />
          Please check your <SpanBold>Work Experience</SpanBold> and{" "}
          <SpanBold>Job Preferences</SpanBold> for accuracy.
          <br /> Thank you!
        </BlankContainer>
      </MiddleRowContainer>

      <BottomContent>
        <UpdateButton variant="text" onClick={navigateToProfile}>
          Let's go!
        </UpdateButton>
      </BottomContent>
    </NotificationPromptContainer>
  );
};

export default SharedJobPreferenceNotification;
