import Box from "@mui/material/Box";
import styled from "styled-components";
import Color from "../../../assets/colors";

export const FacebookButton = styled(Box)`
  && {
    width: 3rem;
    height: 3rem;
    background-color: ${Color.facebookBlue};
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    justify-items: center;
    align-items: center;
    border: 1px solid ${Color.black};
    box-shadow: 2px 2px 0 ${Color.black};
  }
`;
