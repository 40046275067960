import BriefcaseIcon from "@heroicons/react/24/outline/BriefcaseIcon";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/24/solid/ChevronUpIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import SparklesIcon from "@heroicons/react/24/solid/SparklesIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { Chip, OutlinedInput, Radio } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import { default as Link } from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { PopupButton } from "@typeform/embed-react";
import styled from "styled-components";
import Color from "../../../../assets/colors";
import { FontSize } from "../../../../assets/font";
import MYFlag from "../../../../public/images/MY.svg";
import SGFlag from "../../../../public/images/SG.svg";
import Logo from "../../../../public/images/hiredly-logo.svg";

// Menu List Component //
export const MenuListStyled = styling(MenuList)({
  overflow: "hidden !important",
  textOverflow: "ellipsis !important",
});

// Paper Component //
export const PaperStyled = styling(Paper)({
  overflow: "hidden",
  textOverflow: "ellipsis !important",
});

// Menu Item Components //
export const MenuItemStyled = withTheme(styled(MenuItem)`
  && {
    font-weight: ${(props) => (props.$font_bold ? "bold" : null)};
    cursor: ${(props) => (props.$cursor ? "default" : null)};
    width: 100%;
    height: 2rem;
    padding: 0 0 0 15px;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`);

export const NavbarLinksStyled = styling(Button)((props) => ({
  display: "flex",
  padding: "0px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",

  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  lineHeight: "24px",
  fontWeight: "400",
  textTransform: "capitalize",
  textDecoration: "none",

  "&:hover": {
    fontWeight: "700",
    backgroundColor: "transparent",

    textDecoration: "underline",
    textUnderlineOffset: "1.3rem",
    textDecorationThickness: "6px",

    textDecoration: "none",
    transition: "all 0.125s ease-in-out",
  },

  ...(props.$active == true && {
    fontWeight: "700",
    backgroundColor: "transparent",

    textDecoration: "underline",
    textUnderlineOffset: "1.3rem",
    textDecorationThickness: "6px",
  }),

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    margin: "0",
    display: props.$type === "for-you" ? "flex" : "none",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const JobsIcon = styling(BriefcaseIcon)((props) => ({
  height: "24px",
  width: "24px",

  [props.theme.breakpoints.down("sm")]: {
    height: "18px",
    width: "18px",
  },
}));

export const CompanyIcon = styling(BuildingOfficeIcon)((props) => ({
  height: "24px",
  width: "24px",

  [props.theme.breakpoints.down("sm")]: {
    height: "18px",
    width: "18px",
  },
}));

export const BadgeStyled = styling(Badge)({
  "& .MuiBadge-badge ": {
    fontSize: "0.6rem",
    fontWeight: "300",
    letterSpacing: "1.5px",

    borderRadius: "4px",
    padding: "0 0.215rem",
    margin: "0 0 1rem 0",
    height: "17px",
  },
});

export const HomepageButtonContainer = styling(Stack)({
  height: "3.5rem",
  zIndex: "-1",

  borderTop: `1.5px solid ${Color.black}`,
  borderBottom: `1.5px solid ${Color.black}`,
  background: "rgba(234, 231, 245, 1)",
});

export const HomepageButton = styling(Link)((props) => ({
  cursor: "pointer",
  width: "100%",
  height: "3.5rem",
  color: `${Color.hiredlyPurple}`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",

  fontSize: "0.75rem",
  fontWeight: "500",

  letterSpacing: "0.09375rem",
  textTransform: "uppercase",
  textDecoration: "none",
  borderRight: props.jobs_button && `1.5px solid ${Color.black}`,

  [props.theme.breakpoints.down("sm")]: {
    gap: "0.5rem",
  },
}));

// NEW IMPLEMENTATION
export const AppbarStyled = styling(AppBar)((props) => ({
  backgroundColor: props.$employer ? "white" : "hsla(0, 0%, 100%, 0.8)",
  backdropFilter: "blur(0.25rem)",

  "& .MuiToolbar-root": {
    minHeight: "64px",
    maxHeight: "64px",
    "@media(min-width: 1280px)": {
      padding: "10px 40px",
    },

    "@media(max-width: 1279px)": {
      padding: "8px 24px",
      minHeight: "52px",
      maxHeight: "52px",
    },

    "@media(max-width: 639px)": {
      padding: "10px 16px",
      minHeight: "60px",
      maxHeight: "60px",
    },
  },

  [props.theme.breakpoints.down("lg")]: {
    // height: props.$homepage === "true" ? "125px" : "68px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    boxShadow: "none",
    borderBottom: "1px solid lightgrey",
  },
}));

export const LogoStyled = styling("img")((props) => ({
  maxWidth: "132px",
  height: "20px",
  objectFit: "contain",
  paddingBottom: "5px",

  [props.theme.breakpoints.down("lg")]: {
    width: "110px",
    height: "32px",
    paddingBottom: "0px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "97.3px",
    height: "14px",
  },
}));

export const VanillaGrid = styling(Grid)({
  //
});

export const TypographyStyled = styling(Typography)({
  lineHeight: 1,
});

export const ToolbarStyled = styling(Toolbar)((props) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const IconButtonStyled = styling(IconButton)({
  position: "relative",
});

export const MYCountryFlag = styling(MYFlag, {
  shouldForwardProp: (propName) => propName !== "isPopper",
})((props) => ({
  height: "17.143px",
}));

export const SGCountryFlag = styling(SGFlag, {
  shouldForwardProp: (propName) => propName !== "isPopper",
})((props) => ({
  height: "17.143px",
}));

export const CountryOptionName = styling(Typography)((props) => ({
  fontSize: "14px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "12px",
  },
}));

export const ChevronDownStyled = styling(ChevronDownIcon)((props) => ({
  color: Color.black,
  width: 24,
  height: 24,

  [props.theme.breakpoints.down("tablet")]: {
    width: 13,
    height: 13,
  },
}));

export const ChevronUpStyled = styling(ChevronUpIcon)((props) => ({
  color: Color.black,
  width: 24,
  height: 24,

  [props.theme.breakpoints.down("tablet")]: {
    width: 13,
    height: 13,
  },
}));

export const LoginButton = styling(Button)((props) => ({
  width: "100px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0 16px",
  borderRadius: "8px",
  textTransform: "none",
  color: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  backgroundColor: Color.white,

  "&:hover": {
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    width: "80px",
    height: "32px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    padding: "0 24px",
    height: "24px",
    width: "60px",
    marginLeft: "20px",
    borderRadius: "100px",
    fontSize: "12px",
  },
}));

export const SignUpButton = styling(Button)((props) => ({
  width: "100px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0 16px",
  borderRadius: "8px",
  textTransform: "none",
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,

  "&:hover": {
    color: Color.hiredlyPurple,
    backgroundColor: Color.white,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    width: "80px",
    height: "32px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const TextButtonStyled = styling(Button)((props) => ({
  textTransform: "none",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  color: Color.hiredlyPurple,
  display: "flex",
  whiteSpace: "nowrap",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const SearchInputContainer = styling(OutlinedInput)((props) => ({
  padding: "6px 6px 6px 16px",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "80px",
  maxWidth: "360px",
  width: "100%",
  border: `1px solid ${Color.secondaryGrey}`,
  height: "48px",
  fontSize: "16px",
  fontFamily: "Inter",
  lineHeight: "22px",
  backgroundColor: Color.white,

  "& .MuiInputBase-input": {
    padding: 0,
  },

  "&:hover": {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",
  },

  "@media(min-width: 640px) and (max-width: 1279px)": {
    width: "auto",
    height: "36px",
    padding: "6px 8px 6px 16px",
    fontSize: "13px",
  },

  "@media(max-width: 639px)": {
    maxWidth: "224px",
    width: "100%",
    height: "36px",
    padding: "6px 8px 6px 16px",
    fontSize: "13px",
  },

  // [props.theme.breakpoints.down("lg")]: {
  //   padding: "6px 3px 6px 16px",
  //   width: "256px",
  //   marginTop: "8px",
  //   marginBottom: "8px",
  //   height: "36px",
  //   fontSize: "13px",

  //   "& .MuiInputBase-input": {
  //     paddingLeft: "5px",
  //   },
  // },

  // [props.theme.breakpoints.down("tablet")]: {
  //   width: "192px",
  //   boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",

  //   "& fieldset": {

  //   },
  // },
}));

export const MagnifyingGlassIconStyled = styling(MagnifyingGlassIcon)(
  (props) => ({
    width: "20px",
    height: "20px",

    [props.theme.breakpoints.down("lg")]: {
      width: "16px",
      height: "16px",
    },
  })
);

export const LinkContainer = styling(Grid)((props) => ({
  display: "flex",

  ":hover": {
    backgroundColor: Color.lightGrey,
  },
}));

export const LinkStyled = styling("a")((props) => ({
  textDecoration: "none",
  fontSize: "16px",
  fontFamily: "Inter",
  color: Color.black,
  fontWeight: "700",
}));

export const SparkleIconStyled = styling(SparklesIcon)((props) => ({
  color: Color.accentYellow,
}));

export const DrawerLoginButton = styling(Button)((props) => ({
  flex: 1,
  height: "43px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0px 16px",
  borderRadius: "30px",
  textTransform: "none",
  color: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  backgroundColor: Color.white,
  width: "100%",

  ":hover": {
    background: "#EFEFFD",
    fontWeight: 700,
  },
}));

export const DrawerSignUpButton = styling(Button)((props) => ({
  flex: 1,
  height: "43px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0px 16px",
  borderRadius: "30px",
  textTransform: "none",
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  width: "100%",

  "&:hover": {
    background: "#40249B",
  },
}));

export const AvatarStyled = styling(Avatar)((props) => ({
  width: "35px",
  height: "35px",
  cursor: "pointer",
}));

export const PopperStyled = styling(Popper)((props) => ({
  zIndex: "1001",
  //
}));

export const GrowStyled = styling(Grow)((props) => ({
  zIndex: 20,
  width: "210px",
  marginRight: "20px",
  // transformOrigin: "center top",
}));

export const CountryGrowStyled = styling(Grow)((props) => ({
  zIndex: 20,
  marginTop: "25px",
  // marginRight: "20px",
}));

export const ClickAwayListenerStyled = styling(ClickAwayListener)((props) => ({
  //
}));

export const SearchSuggestionContainer = styling(Grid)((props) => ({
  color: Color.black,
  cursor: "pointer",
  padding: "5px 10px",
  borderRadius: "30px",
  width: "100%",

  ":hover": {
    backgroundColor: Color.lightGrey,
    cursor: "pointer",
  },
}));

export const SearchDropdownContainer = styling(Grid)((props) => ({
  borderRadius: "10px",
  backgroundColor: Color.white,
  boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
  width: "427px",
  padding: "20px",
  marginTop: "10px",
  position: "absolute",
  top: "50px",
  zIndex: "1001",

  [props.theme.breakpoints.down("lg")]: {
    width: "370px",
  },
}));

export const RecentSearchText = styling(Typography)((props) => ({
  fontSize: FontSize.desktopCaption,
  fontWeight: FontSize.bold,
  color: Color.black,
}));

export const ChipStyled = styling(Chip)((props) => ({
  backgroundColor: "rgba(81, 42, 204, 0.15)",
  padding: "5px 10px",
  margin: "10px 5px",
  cursor: "pointer",

  ":hover": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },

  ":focus": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },
}));

export const CountryMenuItem = styling(MenuItem)((props) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  columnGap: "10px",
  rowGap: "24px",
  width: "100%",
  cursor: "pointer",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [props.theme.breakpoints.down("lg")]: {
    rowGap: "16px",
  },
}));

export const DrawerCountryToggleGrid = styling(Grid)((props) => ({
  display: "flex",
  maxWidth: !props.mobileSize && "173px",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "24px",
}));

export const TypeformButton = styling(PopupButton)((props) => ({
  border: "none",
  textWrap: "nowrap",
  whiteSpace: "nowrap",
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  fontFamily: "Inter",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: "#40249B",
  padding: 0,

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.112px",
  },
}));

export const XMarkIconStyled = styling(XMarkIcon)((props) => ({
  width: "20px",
  height: "20px",

  [props.theme.breakpoints.down("lg")]: {
    width: "16px",
    height: "16px",
  },
}));

export const NavbarContentContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "16px",
  width: "100%",
  justifyContent: "space-between",

  "@media(min-width: 640px)": {
    justifyContent: "space-between",
  },
});

export const StyledLoginButton = styled(Button)({
  "&&": {
    display: "flex",
    height: "36px",
    width: "auto",
    padding: "0px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    border: "1px solid var(--Primary-Hiredly-Purple, #512ACC)",
    color: "var(--Primary-Hiredly-Purple, #512ACC)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    background: "#FFFFFF",

    ":hover": {
      background: "#EFEFFD",
      fontWeight: 700,
    },
  },
});

export const StyledSignUpButton = styled(Button)({
  "&&": {
    display: "flex",
    height: "36px",
    padding: "0px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "var(--Primary-Hiredly-Purple, #512ACC)",
    color: "var(--Primary-White, #FFF)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "none",
    whiteSpace: "nowrap",

    "&:hover": {
      background: "#40249B",
    },
  },
});

export const StyledOutlinedButton = styled(Button)({
  "&&": {
    display: "flex",
    height: "36px",
    padding: "0px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px !important",
    background: "var(--Grey-Lightest-Grey, #FAFAFA)",
    boxShadow:
      "2px 1.5px 5px 0px rgba(174, 174, 192, 0.40) inset, -4px -4px 4px 0px #FFF inset",
    color: "var(--Secondary-Dark-Purple, #40249B)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textTransform: "capitalize !important",
    whiteSpace: "nowrap",
  },
});

export const LocationContainer = styled("div")({
  display: "flex",
  padding: "2px 8px",
  alignItems: "center",
  gap: "4px",
  alignSelf: "stretch",
  borderRadius: "8px",
  cursor: "pointer",

  ":hover, :active": {
    background: "#F5F5F5",
  },
});

export const CountryText = styled(Typography)({
  "&&": {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
  },
});

export const CTAContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",

  "@media(max-width: 1279px)": {
    gap: "16px",
  },
});

export const DividerLine = styled("div")({
  width: "1px",
  height: "24px",
  background: "#D8D8D8",
});

export const StyledMenuText = styled(Typography)({
  "&&": {
    color: "var(--Text-Light-Theme-High-Emphasis, rgba(0, 0, 0, 0.87))",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    width: "fit-content",
  },
});

export const DrawerContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "40px",
  alignSelf: "stretch",
  "@media(max-width: 450px)": {
    gap: "32px",
  },
});

export const HorizontalDividerLine = styled("div")({
  background: "#D8D8D8",
  width: "100%",
  height: "1px",
});

export const DrawerStyledButton = styled(Button)(({ origin }) => ({
  display: "flex",
  height: "42px",
  width: "100%",
  padding: "0px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flex: "1 0 0",
  borderRadius: "100px",
  border: "1px solid var(--Primary-Hiredly-Purple, #512ACC)",
  textTransform: "none",
  background: origin == "signup" && "#512ACC",
  color: origin == "signup" && "#FFF",

  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: origin == "login" ? 500 : 700,
  lineHeight: "normal",
  letterSpacing: origin == "login" && "0.074px",

  ":hover": {
    background: origin == "signup" && "#512ACC",
  },
}));

export const DrawerCountryText = styled(Typography)({
  color: "var(--Text-Light-Theme-High-Emphasis, rgba(0, 0, 0, 0.87))",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.15px",
});

export const DrawerDropdownRadio = styled(Radio)(({ checked }) => ({
  padding: 0,
  "& svg": {
    fontSize: "15px",
  },

  "& svg[data-testid='RadioButtonCheckedIcon']": {
    transform: checked && "scale(1.2)",
  },
}));

export const HiredlyLogo = styled(Logo)(({ origin }) => ({
  height: "20px",

  "@media(max-width: 1279px)": {
    height: origin == "navbar" && "14px",
  },
}));

export const StyledMapPin = styled(MapPinIcon)({
  width: "18px",
  height: "18px",
  color: "#222222",
});
